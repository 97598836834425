<template>
  <div class="container">
    <canvas id="renderCanvas" style="outline:none;"></canvas>
    <div class="mask2"></div>
    <div class="mask" v-if="show">
      <div class="mid">
        <div class="loading_w"></div>
        <div class="loading_c">
          <div class="loading_up"></div>
        </div>
        <div class="jingdu">
          <div class="bar_label" :style="{left:`calc(${bar}% - 10px)`}">{{ bar }}%</div>
          <div class="bar" :style="{width:bar + '%'}">
          </div>
        </div>
      </div>
    </div>
    <div class="mask3" v-show="isPopInfo">
      <div class="pop_info" id="pop_info">
        <div class="close-icon" @click="closePop"></div>
        <div class="title">
          <p class="text" id="pop_name">{{ buildingName }}</p>
        </div>
        <div class="content">
          <div class="content_">
            <span class="text">状态：</span>
            <span class="text" v-if="buildingContent">{{ buildingContent.status }}</span>
          </div>
          <div class="content_">
            <span class="text">使用率：</span>
            <span class="text" v-if="buildingContent">{{ buildingContent.usage }}</span>
          </div>
          <div class="content_">
            <span class="text">建筑面积：</span>
            <span class="text" v-if="buildingContent">{{ buildingContent.area }}</span>
          </div>
          <div class="content_">
            <span class="text">容纳人数：</span>
            <span class="text" v-if="buildingContent">{{ buildingContent.number }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="mask3" v-show="isAlarmPopInfo">
      <div class="alarm_pop_info" id="alarm_pop_info">
        <div class="close-icon" @click="isAlarmPopInfo=false"></div>
        <div class="title">
          <p class="text" id="alarm_pop_name">{{ alarmInfo.name }}</p>
        </div>
        <div class="content">
          <div class="content_">
            <span class="text">描述：</span>
            <span class="text">{{ alarmInfo.remark }}</span>
          </div>
          <div class="content_">
            <span class="text">位置：</span>
            <span class="text">{{ alarmInfo.location }}</span>
          </div>
          <div class="content_">
            <span class="text">上报时间：</span>
            <span class="text">{{ alarmInfo.time }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="chose_move" v-show="false">
      <p class="hospital" @click="showHospitalInfo">医院</p>
      <p class="teaching" @click="showBuildingInfo">教学楼</p>
    </div>
    <div class="showFrames" @click="getPosition" v-if="false">
      <p>current frame time (GPU): {{ instrumentation.one }} ms</p>
      <p>average frame time (GPU): {{ instrumentation.two }} ms</p>
      <p>total shader compilation time: {{ instrumentation.three }} ms</p>
      <p>average shader compilation time: {{ instrumentation.four }} ms</p>
      <p>compiler shaders count: {{ instrumentation.five }}</p>
      <p>frame number: {{ instrumentation.six }}</p>
      <p>draw calls: {{ instrumentation.seven }}</p>
    </div>
  </div>
</template>

<script>
import * as BABYLON from "@babylonjs/core/Legacy/legacy";
import "@babylonjs/loaders/glTF/2.0";
import {HiScene} from "../../sdk/hiscene";
import {HiWaterMaterial} from "../../sdk/water";
import {HiBillboardSystem} from "../../sdk/hibillboard";
import {HiBaseObject} from "../../sdk/hiobject";
import axios from "axios";
import Global from "../js/global";
import Message from "../js/Message";
import DeviceAPI from "../js/deviceAPI";
import HiConfig from "@/HiConfig";

let hiScene = null;
let observer = null;
let gltfArr = null;
let animationArr = {
  car: "",
  bus: "",
  skyShuttle: "",
  bird: ""
}
let buildingLabel = {
  one: {
    url: require("../assets/image/building/building_one.png"),
    position: new BABYLON.Vector3(-185.00557358577527, 107.22377586364745, 14.809145112899813),
    width: 10.0,
    height: 6.2
  },
  two: {
    url: require("../assets/image/building/building_two.png"),
    position: new BABYLON.Vector3(-189.4168176489234, 107.20028686523436, 165.7496039319094),
    width: 15.0,
    height: 6.2
  },
  three: {
    url: require("../assets/image/building/building_three.png"),
    position: new BABYLON.Vector3(-95.27499130552343, 107.44832420349118, -70.38394281330622),
    width: 16.0,
    height: 6.2
  },
  four: {
    url: require("../assets/image/building/building_four.png"),
    position: new BABYLON.Vector3(9.261631819972195, 119.1135932001423, -172.28815032867044),
    width: 10.0,
    height: 6.2
  },
  five: {
    url: require("../assets/image/building/building_five.png"),
    position: new BABYLON.Vector3(12.934067909903945, 114.10020828247076, 57.86779780712284),
    width: 36.0,
    height: 6.2
  },
  six: {
    url: require("../assets/image/building/building_six.png"),
    position: new BABYLON.Vector3(-319.26153298839307, 104.50598622338659, 5.687250331856831),
    width: 16.0,
    height: 6.2
  },
  seven: {
    url: require("../assets/image/building/building_seven.png"),
    position: new BABYLON.Vector3(-312.3748889351093, 105.74987857811965, 180.7908278892785),
    width: 18.0,
    height: 6.2
  },
  eight: {
    url: require("../assets/image/building/building_eight.png"),
    position: new BABYLON.Vector3(56.84806937777944, 107.80513954162595, 413.50868940336693),
    width: 10.0,
    height: 6.2
  },
  nine: {
    url: require("../assets/image/building/building_nine.png"),
    position: new BABYLON.Vector3(-47.08009765626343, 105.05788573998541, 250.73105878531268),
    width: 10.0,
    height: 6.2
  },
  ten: {
    url: require("../assets/image/building/building_ten.png"),
    position: new BABYLON.Vector3(-99.34751894681443, 110.78010264641085, 310.2631780540676),
    width: 16.0,
    height: 6.2
  },
  eleven: {
    url: require("../assets/image/building/building_eleven.png"),
    position: new BABYLON.Vector3(-91.95891577999328, 107.74034542596502, 389.2801625481765),
    width: 16.0,
    height: 6.2
  }
}
let buildingTextInfo = [
  {name: "中医", content: {status: '', usage: "", area: "", number: ""}},
  {name: "针灸推拿", content: {status: '', usage: "", area: "", number: ""}},
  {name: "中西医结合", content: {status: '', usage: "", area: "", number: ""}},
  {name: "中药", content: {status: '', usage: "", area: "", number: ""}},
  {name: "图书馆、标本馆、综合教学楼", content: {status: '', usage: "", area: "", number: ""}},
  {name: "行政综合楼", content: {status: '', usage: "", area: "", number: ""}},
  {name: "学术会议中心", content: {status: '使用中', usage: "80%", area: "20000平方米", number: "1000人"}},
  {name: "体育馆", content: {status: '', usage: "", area: "", number: ""}},
  {name: "食堂", content: {status: '', usage: "", area: "", number: ""}},
  {name: "宿舍S1~4", content: {status: '', usage: "", area: "", number: ""}},
  {name: "宿舍S5~7", content: {status: '', usage: "", area: "", number: ""}}
]
let pointerObserver = {
  one: "",
  two: "",
  three: "",
  four: "",
  five: "",
  six: ""
}
let floorArr = null
let changeTreeArr = []
let deviceNameArr = []
let areaPlane = []
let alarmPlane = []

export default {
  name: "Model",
  data() {
    return {
      instrumentation: {
        one: '',
        two: '',
        three: '',
        four: '',
        five: '',
        six: '',
        seven: ''
      },
      chosePoint: null,
      alarmChosePoint: null,
      show: true,
      isProgress: true,
      bar: 1,
      isSunny: true,
      isFog: false,
      isRain: false,
      isSnow: false,
      textureImg: {
        blueUrl: require("../assets/image/bg/blue.png"),
        yellowUrl: require("../assets/image/bg/yellow.png")
      },
      isAreaOne: false,
      isAreaTwo: false,
      isAreaThree: false,
      blue: new BABYLON.Vector4(48.0 / 255.0, 214.0 / 255.0, 194.0 / 255.0, 125.0 / 255.0),
      yellow: new BABYLON.Vector4(248.0 / 255.0, 216.0 / 255.0, 176.0 / 255.0, 125.0 / 255.0),
      isAnimation: false,
      plane: null,
      skybox: {
        type: "1-IMG",
        url: ""
      },
      envUrl: {
        sunny: "https://10.32.102.170:28081/model/model/sunny.env",
        night: "https://10.32.102.170:28081/model/model/night_1.env"
      },
      lampLightArr: [],
      buildingName: "",
      buildingContent: null,
      alarmInfo: '',
      areaImg: [
        require("../assets/image/building/area_one.png"),
        require("../assets/image/building/area_two.png"),
        require("../assets/image/building/area_three.png")
      ],
      isPopInfo: false,
      planePosition: [
        new BABYLON.Vector3(-185.00557358577527, 107.22377586364745, 14.809145112899813),
        new BABYLON.Vector3(-189.4168176489234, 107.20028686523436, 165.7496039319094),
        new BABYLON.Vector3(-95.27499130552343, 107.44832420349118, -70.38394281330622),
        new BABYLON.Vector3(9.261631819972195, 119.1135932001423, -172.28815032867044),
        new BABYLON.Vector3(12.934067909903945, 114.10020828247076, 57.86779780712284),
        new BABYLON.Vector3(-319.26153298839307, 104.50598622338659, 5.687250331856831),
        new BABYLON.Vector3(-312.3748889351093, 105.74987857811965, 180.7908278892785),
        new BABYLON.Vector3(56.84806937777944, 107.80513954162595, 413.50868940336693),
        new BABYLON.Vector3(-47.08009765626343, 105.05788573998541, 250.73105878531268),
        new BABYLON.Vector3(-99.34751894681443, 110.78010264641085, 310.2631780540676),
        new BABYLON.Vector3(-91.95891577999328, 107.74034542596502, 389.2801625481765)
      ],
      isAlarmPopInfo: false,
      isFloor: true,
    };
  },
  created() {
  },
  mounted() {
    this.createCanvas();
    deviceNameArr = DeviceAPI.getDeviceData();
    Global.Func.mode = this.changeMode;
    Global.Func.rain = this.rainMode;
    Global.Func.snow = this.snowMode;
    Global.Func.fog = this.fogMode;
    Global.Func.animation = this.loadAnimation;
    Global.Func.openSpecialEffects = this.openSpecialEffects;
    Global.Func.closeSpecialEffects = this.closeSpecialEffects;
    // this.changeCameraTarget()
  },
  unmounted() {
    for (let index in pointerObserver) {
      window.scene.onPointerObservable.remove(pointerObserver["index"]);
    }
    window.canvas = null;
    window.camera = null;
    window.scene = null;
    window.engine.dispose();
    window.engine = null;
    hiScene = null;
  },
  watch: {
    $route: function (newRouter, oldRouter) {
      this.isPopInfo = false
      this.isAlarmPopInfo = false
      let name = this.$route.name;
      console.log(name)
      if (name) {
        let point = Global.views[name];
        if (!point) {
          point = Global.views.BuildingAutomation
        }
        Global.flyTo(point);
      }
    }
  },
  methods: {
    async createEngine() {
      const webGPUSupported = await BABYLON.WebGPUEngine.IsSupportedAsync;
      console.log("webGPUSupported", webGPUSupported)
      if (webGPUSupported) {
        const engine = new BABYLON.WebGPUEngine(document.getElementById("renderCanvas"));
        await engine.initAsync();
        return engine;
      }
      return new BABYLON.Engine(document.getElementById("renderCanvas"), true);
    },
    createCanvas() {
      let canvas = document.getElementById("renderCanvas");
      let engine = new BABYLON.Engine(canvas, true, {preserveDrawingBuffer: true, stencil: true});
      // let engine1 = this.createEngine()
      // console.log("engine1", engine1)
      // 场景性能优化
      engine.enableOfflineSupport = false;
      // 关闭对上下文丢失恢复的支持
      engine.doNotHandleContextLost = true;
      // Instrumentation
      let instrumentation = new BABYLON.EngineInstrumentation(engine);
      instrumentation.captureGPUFrameTime = true;
      instrumentation.captureShaderCompilationTime = true;

      let scene = this.createScene(engine, canvas);
      scene.imageProcessingConfiguration.contrast = 1.6
      // 场景优化
      scene.autoClear = false; // Color buffer
      // scene.skipPointerMovePicking = true
      let sceneInstrumentation = new BABYLON.SceneInstrumentation(scene);
      sceneInstrumentation.captureActiveMeshesEvaluationTime = true;
      sceneInstrumentation.captureFrameTime = true;

      window.scene = scene;
      window.engine = engine;

      scene.fogMode = BABYLON.Scene.FOGMODE_LINEAR;
      //BABYLON.Scene.FOGMODE_NONE;
      // BABYLON.Scene.FOGMODE_EXP;
      //BABYLON.Scene.FOGMODE_EXP2;
      //BABYLON.Scene.FOGMODE_LINEAR;
      // scene.fogDensity = 0.00001
      // 不显示雾
      scene.fogStart = 10000;
      scene.fogEnd = 10000;
      scene.fogColor = new BABYLON.Color3(255 / 255, 255 / 255, 255 / 255);

      window.myRainParticleSystem = Global.myRainParticleSystem();
      window.mySnowParticleSystem = Global.mySnowParticleSystem();

      this.getSceneInfo();

      scene.registerAfterRender(function () {
        that.instrumentation.one = (instrumentation.gpuFrameTimeCounter.current * 0.000001).toFixed(2)
        that.instrumentation.two = (instrumentation.gpuFrameTimeCounter.average * 0.000001).toFixed(2)
        that.instrumentation.three = (instrumentation.shaderCompilationTimeCounter.total).toFixed(2)
        that.instrumentation.four = (instrumentation.shaderCompilationTimeCounter.average).toFixed(2)
        that.instrumentation.five = instrumentation.shaderCompilationTimeCounter.count
        that.instrumentation.six = engine.getFps().toFixed(2)
        that.instrumentation.seven = sceneInstrumentation.frameTimeCounter.current.toFixed(2);
      })
      // 编辑器
      const that = this;
      // 信息弹窗
      let infoPop = document.getElementById("pop_info");
      let alarmInfoPop = document.getElementById("alarm_pop_info");
      engine.runRenderLoop(function () {
        // 每一帧计算弹窗的位置
        if ((that.chosePoint) && that.isPopInfo) {
          let vector2 = Global.vector3Tovector2(that.chosePoint);
          // 移到弹窗位置到模型处
          let moveX = vector2.x;
          let moveY = vector2.y;
          if ((moveX + 471.78 * 3 / 4) > document.documentElement.clientWidth) {
            moveX = document.documentElement.clientWidth - 471.78 * 3 / 4;
          }
          if ((moveY + 291 * 3 / 4) > document.documentElement.clientHeight) {
            moveY = document.documentElement.clientHeight - 291 * 3 / 4;
          }
          infoPop.style.left = moveX + "px";
          infoPop.style.top = moveY + "px";
        }
        if (that.alarmChosePoint && that.isAlarmPopInfo) {
          let vector2 = Global.vector3Tovector2(that.alarmChosePoint);
          let moveX = vector2.x;
          let moveY = vector2.y;
          if ((moveX + 471.78 * 3 / 4) > document.documentElement.clientWidth) {
            moveX = document.documentElement.clientWidth - 471.78 * 3 / 4;
          }
          if ((moveY + 291 * 3 / 4) > document.documentElement.clientHeight) {
            moveY = document.documentElement.clientHeight - 291 * 3 / 4;
          }
          alarmInfoPop.style.left = moveX + "px";
          alarmInfoPop.style.top = moveY + "px";
        }

        // 模型加载好后关闭进度条
        if (gltfArr && that.isProgress) {
          that.getProgress();
        }
        // 楼层模型加载好后
        if (floorArr && that.isFloor) {
          that.initGltf()
        }
        scene.render();
      });
      window.addEventListener("resize", function () {
        engine.resize();
      });
    },
    createScene(engine, canvas) {
      let scene = new BABYLON.Scene(engine);
      scene.clearColor = new BABYLON.Color4(0, 0, 0, 0);
      let camera = new BABYLON.ArcRotateCamera("Camera", Math.PI, Math.PI * 3 / 8, 1000, new BABYLON.Vector3(0, 0, 200), scene);

      camera.attachControl(canvas, true);
      camera.lowerRadiusLimit = 0;
      camera.upperRadiusLimit = 1500;

      // 设置平移灵敏度
      scene.activeCamera.panningSensibility = 30;

      window.camera = camera;

      camera.attachControl(canvas, true);
      Global.camera = window.camera;
      return scene;
    },
    async getSceneInfo() {
      let that = this;
      let scene = window.scene;
      let sceneUrl = HiConfig.sceneUrl
      let rootUrl = HiConfig.rootUrl
      // 1f76d7ed-71b6-4b36-9035-f2bd6a43dd60（小）   51867b82-267f-4f20-bf47-2c3004a7664d (大)    8c51f3c0-542e-41a6-b722-f9162b92c5a4 (中)
      axios.get(sceneUrl).then(async res => {
        console.log("res", res)
        let scene_info = JSON.parse(res.data.scene_info);
        console.log("scene_info", scene_info)
        // 更换模型位置
        scene_info.objects = this.changeModelPosition(scene_info.objects);
        hiScene = HiScene.FromData(scene, scene_info, rootUrl);
        console.log("所有模型", hiScene.objects)
        Global.hiScene = hiScene;
        this.carAnimation();
        this.busAnimation();
        this.skyShuttleAnimation();
        this.showAlarm();
        this.waterFlow()
        this.initBillboard()
        gltfArr = hiScene.objects.filter(item => item.type === "gltf" && !(item.name.match("一期") && item.name.match("F")));
        floorArr = hiScene.objects.filter(item => item.type === "gltf" && (item.name.match("一期") && item.name.match("F")));

        // 调整相机视角
        let name = this.$route.name;
        let point = Global.views[name];
        Global.flyTo(point);
      }).catch(err => {
        console.log(err);
      });
    },
    // 获取设备的位置信息
    getDevicePos() {
      for (const deviceName of deviceNameArr) {
        let lastIndex = deviceName.lastIndexOf("-")
        // 包含设备集合的模型名称，如：7号楼-1F-广播
        let deviceModelName = deviceName.slice(0, lastIndex)
        // 设备id，如：node8
        let deviceID = deviceName.slice(lastIndex + 1, deviceName.length)
        let deviceModel = hiScene.findObjectByName(deviceModelName)
        if (!deviceModel) {
          break
        }

        let front = deviceModelName.indexOf("-")
        let back = deviceModelName.lastIndexOf("-")
        if (!back || !front) {
          // continue;
        }
        // 定位到建筑
        let buildingName = deviceModelName.slice(0, front)
        // 定位到楼层
        let FloorName = deviceModelName.slice(front + 1, back)
        // 定位到设备类型
        let deviceType_ = deviceModelName.slice(back + 1, deviceModelName.length)
        let buildingIndex = ''
        let floorIndex = ''
        let deviceType
        // 转换名称为对象属性名
        switch (buildingName) {
          case "7号楼":
            buildingIndex = "buildingSeven";
            break
          default:
            break
        }
        switch (FloorName) {
          case "1F":
            floorIndex = "floorOne";
            break
          case "2F":
            floorIndex = "floorTwo";
            break
          case "3F":
            floorIndex = "floorThree";
            break
          default:
            break
        }
        switch (deviceType_) {
          case "教室管理":
            deviceType = "manage";
            break
          case "监控":
            deviceType = "monitor";
            break
          case "广播":
            deviceType = "broadcast";
            break
          case "灯光":
            deviceType = "light";
            break
          case "水箱":
            deviceType = "waterTank";
            break
          case "电箱":
            deviceType = "powerBox";
            break
          case "CO监测器":
            deviceType = "coMonitor";
            break
          case "集水坑监测器":
            deviceType = "waterMonitor";
            break
          case "排风扇":
            deviceType = "Fan";
            break
          case "电梯":
            deviceType = "lift";
            break
          case "空调":
            deviceType = "airConditioning";
            break
          case "发电机":
            deviceType = "dynamo";
            break
          default:
            break
        }

        // 设备id与接口数据对应
        let deviceCoreInfo = DeviceAPI.deviceCoreInfo[deviceType].find(item => item.deviceName === deviceName)
        // 接口数据的设备
        let deviceOfAPI = DeviceAPI.deviceInterfaceData[deviceType].find(item => item.DEVICE_ID === deviceCoreInfo.deviceID)

        deviceModel.meshes.forEach(mesh => {
          if (mesh.metadata && mesh.id === deviceID) {
            let device = {
              type: deviceType,
              name: deviceCoreInfo.deviceID,
              pos: mesh._absolutePosition
            }
            // 广播设备会有一个状态信息
            if (deviceType === "broadcast" || deviceType === "light") {
              device.isOpen = deviceOfAPI.DEVICE_STATUS
            }
            DeviceAPI.devicePos.inside[buildingIndex][floorIndex].push(device)
          }
        })
      }
      DeviceAPI.isGetDevicePos = true
    },
    changeBillboard() {
      let oldBillboard = hiScene.objects.filter(item => item.type === "fast_billboard")
      // console.log('oldBillboard', oldBillboard)
    },
    getProgress() {
      let length = gltfArr.length;
      let num = 0;
      for (let item of gltfArr) {
        if (item.meshes && item.meshes[0]) {
          num = num + 1;
        }
      }
      let progress = num / length * 100
      this.bar = progress.toFixed(1);
      if (progress < 1) {
        this.bar = 0
      }
      if (progress === 100) {
        Global.progress = 100
        this.show = false;
        this.$parent.toggleShow()
        this.isProgress = false;
        // 使用函数(需要meshes属性)
        this.showHospitalInfo();
        this.showTownInfo();
        this.showBuildingInfo();
        this.initGltf();
        this.collisionCheck();
        this.showArea();
        if (!DeviceAPI.isGetDevicePos) {
          this.getDevicePos()
        }
        DeviceAPI.setIconNew();

        this.getBuildingPos()
        // 删除缓存的顶点数据
        // scene.clearCachedVertexData();
        // 删除缓存的纹理缓冲区
        // scene.cleanCachedTextureBuffer();
      }
    },
    // 将楼层模型移到最后显示
    changeModelPosition(modelArr) {
      // 移动billboard位置
      let billboard = modelArr.filter(item => item.type === "billboard" && item.positions.length !== 0)
      modelArr = modelArr.filter(item => item.type !== "billboard")


      // 1、billboard去重
      // let billboard_ = []
      // let flag = true
      // for (let i = 0; i < billboard.length; i++) {
      //   flag = true
      //   for (let j = 0; j < billboard_.length; j++) {
      //     if (billboard[i].name === billboard_[j].name) {
      //       flag = false
      //       break
      //     }
      //   }
      //   if (flag) {
      //     billboard_.push(billboard[i])
      //   }
      // }
      // 3、更改billboard类型为fast_billboard
      billboard.forEach(item => item.type = "fast_billboard")
      // billboard = []
      modelArr = billboard.concat(modelArr)

      // 获取建筑模型
      // 一期教学楼
      let building = modelArr.filter(item => {
        if (item.name.match("一期") && item.name.match("号楼")) {
          return !item.name.match("F");
        } else {
          return false;
        }
      });
      // 一期教学楼楼层
      let floor = modelArr.filter(item => item.name.match("一期") && item.name.match("F"));
      // 场景地面
      let ground = modelArr.filter(item => item.name.match("pro2"));

      // 移除一期教学楼、教学楼楼层、地面
      let buildingRemove = modelArr.filter(item => !(item.name.match("一期") && item.name.match("号楼"))
          && !item.name.match("pro2"));
      let newBuilding_1 = building.concat(ground);

      let newBuilding_2 = newBuilding_1.concat(buildingRemove);

      let finalBuilding = newBuilding_2.concat(floor);

      let twoThere = finalBuilding.filter(item => !item.name.match("中医药23期"));

      // finalBuilding = finalBuilding.filter(item => {
      //   if (!(item.name.match("一期") && item.name.match("F")) && !item.name.match("中医药23期")) {
      //     return true
      //   }
      // })

      return finalBuilding;
    },
    // 初始化场景中的模型
    initGltf() {
      let length = floorArr.length
      let num = 0
      for (let item of floorArr) {
        if (item.meshes && item.meshes[0]) {
          num = num + 1;
        }
      }
      if (num === length) {
        this.isFloor = false
        // 隐藏楼层
        floorArr.forEach(item => item.bNode.setEnabled(false));
        let allGltf = hiScene.objects.filter(item => item.type === "gltf");
        allGltf.forEach(model => {
          model.meshes.forEach(mesh => {
            // 场景性能优化：不更新模型的边界信息
            // mesh.freezeWorldMatrix()
            // mesh.alwaysSelectAsActiveMesh = true
          })
        })
        let twoThree = hiScene.objects.filter(item => item.type === "gltf" && item.name.match("中医药23期"));
        let meshNum = 0
        twoThree.forEach(model => {
          meshNum += model.meshes.length
        })
      }
    },
    // 初始化billboard
    initBillboard() {
      let billboard = hiScene.objects.filter(item => item.type === "fast_billboard")
      billboard.forEach(item => {
        item._sps.particles.forEach(mesh => {
          if ((mesh.position.z <= -301) || (mesh.position.z > -301 && mesh.position.z < -14 && mesh.position.x < -184) ||
              (mesh.position.z > -301 && mesh.position.z < -14 && mesh.position.x < mesh.position.z * -0.8 - 192)) {
            changeTreeArr.push(mesh)
          }
        })
      })
      // console.log("changeTreeArr", changeTreeArr)
      // console.log("树的总数", count)
    },
    // 白天黑夜模式切换
    changeMode() {
      this.isSunny = !this.isSunny;
      let billBoardSystem = hiScene.objects.filter(item => item.type === "billboard");
      let waterMesh = hiScene.findObjectByName("水面");

      if (hiScene && hiScene.sceneLight.enabled) {
        if (this.isSunny) {
          this.skybox.url = this.envUrl.sunny;
          hiScene.setSkyBoxData(this.skybox);
          hiScene.setEnvTextureData({url: this.envUrl.sunny});
          waterMesh.customMaterial.waterColor = [116.0 / 255.0, 185.0 / 255.0, 250.0 / 255.0];
          waterMesh.customMaterial.colorBlendFactor = 0.5;

          // 光照强度
          hiScene.sceneLight.intensity = 9;
          hiScene.sceneLight.color = [255 / 255, 255 / 255, 255 / 255];
          hiScene.sceneLight.direction = [10, -20, 15];
          // 环境光强度
          hiScene.sceneLight.ambientIntensity = 1;
          hiScene.sceneLight.ambientColor = [255 / 255, 255 / 255, 255 / 255];
          hiScene.sceneLight.bAmbientColor = [255 / 255, 255 / 255, 255 / 255];
          // billBoardSystem.forEach(item => item.ambientColor = new BABYLON.Color3(255 / 255, 255 / 255, 255 / 255))
          // 设置路灯
          this.lampLightArr.forEach(item => {
            let lampLight_ = scene.getLightByName(item);
            lampLight_.setEnabled(false);
          });
        } else {
          this.skybox.url = this.envUrl.night;
          hiScene.setSkyBoxData(this.skybox);
          hiScene.setEnvTextureData({url: this.envUrl.night});
          waterMesh.customMaterial.waterColor = [25.0 / 255.0, 25.0 / 255.0, 25.0 / 255.0];
          waterMesh.customMaterial.colorBlendFactor = 0.5;

          hiScene.sceneLight.intensity = 0;
          hiScene.sceneLight.color = [255 / 255, 112 / 255, 87 / 255];
          hiScene.sceneLight.direction = [-10, -20, -15];
          // 环境光强度
          hiScene.sceneLight.ambientIntensity = 0.0;
          hiScene.sceneLight.ambientColor = [255 / 255, 112 / 255, 87 / 255];
          hiScene.sceneLight.bAmbientColor = [55 / 255, 55 / 255, 55 / 255];
          // billBoardSystem.forEach(item => item.ambientColor = new BABYLON.Color3(255 / 255, 112 / 255, 87 / 255))
          // 设置路灯
          this.lampLightArr.forEach(item => {
            let lampLight_ = scene.getLightByName(item);
            lampLight_.setEnabled(true);
          });
        }
      }
      // this.setLamp()
      return this.isSunny;
    },
    // 雨模式
    rainMode() {
      let scene = window.scene;
      // myRainParticleSystem = window.myRainParticleSystem
      this.isRain = !this.isRain;
      // Create a particle system
      if (this.isRain) {
        // Start the particle system
        myRainParticleSystem.start();
        if (this.isSnow) {
          mySnowParticleSystem.stop();
          mySnowParticleSystem.reset();
          this.isSnow = false;
        }
        if (this.isFog) {
          scene.fogStart = 10000;
          scene.fogEnd = 10000;
          this.isFog = false;
        }
      } else {
        myRainParticleSystem.stop();
        myRainParticleSystem.reset(); //Reset to empty system
      }
      return {isRain: this.isRain, isSnow: this.isSnow, isFog: this.isFog};
    },
    // 雪模式
    snowMode() {
      let scene = window.scene;
      // mySnowParticleSystem = window.mySnowParticleSystem
      this.isSnow = !this.isSnow;
      // Create a particle system
      if (this.isSnow) {
        // Start the particle system
        mySnowParticleSystem.start();
        if (this.isRain) {
          myRainParticleSystem.stop();
          myRainParticleSystem.reset();
          this.isRain = false;
        }
        if (this.isFog) {
          scene.fogStart = 10000;
          scene.fogEnd = 10000;
          this.isFog = false;
        }
      } else {
        mySnowParticleSystem.stop();
        mySnowParticleSystem.reset(); //Reset to empty system
      }
      return {isRain: this.isRain, isSnow: this.isSnow, isFog: this.isFog};
    },
    // 雾模式
    fogMode() {
      let scene = window.scene;
      let billBoardSystem = hiScene.objects.filter(item => item.type === "billboard");
      this.isFog = !this.isFog;
      // Fog
      if (this.isFog) {
        if (this.isRain) {
          myRainParticleSystem.stop();
          myRainParticleSystem.reset();
          this.isRain = false;
        }
        if (this.isSnow) {
          mySnowParticleSystem.stop();
          mySnowParticleSystem.reset();
          this.isSnow = false;
        }
        scene.fogStart = 0;
        scene.fogEnd = 5000;
        billBoardSystem.forEach(item => item.ambientColor = new BABYLON.Color3(230 / 255, 230 / 255, 230 / 255));
      } else {
        scene.fogStart = 10000;
        scene.fogEnd = 10000;
        // let billBoardSystem = hiScene.objects.filter( item => item.type === "billboard")
        billBoardSystem.forEach(item => item.ambientColor = new BABYLON.Color3(255 / 255, 255 / 255, 255 / 255));
      }
      return {isRain: this.isRain, isSnow: this.isSnow, isFog: this.isFog};
    },
    // 碰撞检测
    collisionCheck() {
      let scene = window.scene;
      let camera = window.camera;
      //Simple crate
      // let box = BABYLON.MeshBuilder.CreateBox("crate", {size: 100}, scene);
      // box.position = new BABYLON.Vector3(-300, 200, 200);
// Enable Collisions
      scene.collisionsEnabled = true;

      //Then apply collisions and gravity to the active camera
      camera.checkCollisions = true;
      // camera.applyGravity = true;
      camera.collisionRadius = new BABYLON.Vector3(10, 10, 10);

      //Set the ellipsoid around the camera (e.g. your player's size)
      camera.ellipsoid = new BABYLON.Vector3(1, 1, 1);

      //finally, say which mesh will be collisionable
      // ground.checkCollisions = true;
      // box.checkCollisions = true;&&item.name.slice(0, 2)==="一期"
      let gltf = hiScene.objects.filter(item => item.type === "gltf");
      gltf.forEach(item => {
        if (item) {
          item.meshes.forEach(mesh => mesh.checkCollisions = true);
        }
      });
    },
    // 显示区域
    showArea() {
      // console.log("showArea");
      let that = this;
      let lightWall_1 = hiScene.findObjectByName("新一期");
      let lightWall_2 = hiScene.findObjectByName("新二期");
      let lightWall_3 = hiScene.findObjectByName("新三期");
      let lightWall_4 = hiScene.findObjectByName("新四期");
      if (!lightWall_1 || !lightWall_2 || !lightWall_3 || !lightWall_4) {
        return;
      }
      if (lightWall_1.meshes[0] && lightWall_2.meshes[0] && lightWall_3.meshes[0] && lightWall_4.meshes[0]) {
        start();
      } else {
        // console.log('无meshes')
      }

      function start() {
        let areaTitle = document.getElementById("pop_name");
        let areaContent = document.getElementById("pop_content");
        let areaInfo = {
          areaOne: {
            title: "重庆中医药学院一期",
            content: "位于璧山区璧南河以西，黛山大道以东，渝蓉高速公路以北，距重庆医科大学（缙云校区）直线距离约8公里，距轨道1号线璧山站直线距离约3公里。" +
                "项目占地面积约645.55亩，总建筑面积为21.076万平米。\n"
          },
          areaTwo: {
            title: "重庆中医药学院二期",
            content: "简介："
          },
          areaThree: {
            title: "重庆中医药学院三期",
            content: "简介："
          }
        };
        let blue = new BABYLON.Vector4(48.0 / 255.0, 214.0 / 255.0, 194.0 / 255.0, 255.0 / 255.0);
        // 区域图标
        let planeArr = [];
        let areaPosition = [
          new BABYLON.Vector3(-69.77118248520696 - 4.0, 91.9946188876952 + 5.0, 138.84843911021977),
          new BABYLON.Vector3(-178.14076579037385 - 5.0, 96.699951171875 + 5.0, -292.4573784226338),
          new BABYLON.Vector3(-277.0663366627614 - 5.0, 106.8499984741211 + 5.0, -740.7318122167974)
        ];
        let plane = BABYLON.MeshBuilder.CreatePlane("plane", {height: 118 / 4, width: 92 / 4});
        for (let i = 0; i < that.areaImg.length; i++) {
          let plane_ = plane.clone("planeArea_" + i);

          let materialPlane = new BABYLON.StandardMaterial("Mat", scene);
          materialPlane.diffuseTexture = new BABYLON.Texture(that.areaImg[i], scene);
          materialPlane.diffuseTexture.hasAlpha = true;
          materialPlane.backFaceCulling = true;
          materialPlane.ambientColor = new BABYLON.Color3(1, 1, 1);
          materialPlane.disableLighting = true;

          plane_.material = materialPlane;
          //Add text to dynamic texture
          // plane_.visibility = 0.0
          plane_.billboardMode = 7;
          plane_.position = areaPosition[i];
          // plane_.position.z -= (plane_.getBoundingInfo().maximum.x - plane_.getBoundingInfo().minimum.x) / 2;
          plane_.position.y += (plane_.getBoundingInfo().maximum.y - plane_.getBoundingInfo().minimum.y) / 2;
          plane_.scaling = new BABYLON.Vector3(1.5, 1.5, 1.5)
          plane_.enablePointerMoveEvents = true;
          planeArr.push(plane_);
        }
        plane.dispose();
        areaPlane = planeArr;

        // 初始化光墙
        let lightWallArr = [lightWall_1, lightWall_2, lightWall_3, lightWall_4];
        lightWallArr.forEach(lightWall => {
          // console.log('lightWall', lightWall.meshes)
          if (lightWall.meshes) {
            let shaderMaterial = Global.setLightWall(that.textureImg.blueUrl, blue, lightWall);
            lightWall.meshes.forEach(item => item.material = shaderMaterial);
          }
        });

        // 绑定鼠标监听事件
        pointerObserver.three = scene.onPointerObservable.add((pointerInfo) => {
          let eventType = pointerInfo.event.type;
          if (!pointerInfo.pickInfo.pickedMesh) {
            return;
          }
          let meshName = pointerInfo.pickInfo.pickedMesh.name;
          if (!meshName.match("planeArea_")) {
            return;
          }
          if (pointerInfo.event.button === 0 && eventType === "pointerdown") {
            // 消除弹窗已有位置的影响
            that.chosePoint = pointerInfo.pickInfo.pickedPoint;
            switch (meshName) {
              case "planeArea_0":
                test(lightWall_1);
                that.isPopInfo = true;
                areaTitle.innerText = areaInfo.areaOne.title;
                areaContent.innerText = areaInfo.areaOne.content;
                break;
              case "planeArea_1":
                test(lightWall_2);
                that.isPopInfo = true;
                areaTitle.innerText = areaInfo.areaTwo.title;
                areaContent.innerText = areaInfo.areaTwo.content;
                break;
              case "planeArea_2":
                test(lightWall_3);
                that.isPopInfo = true;
                areaTitle.innerText = areaInfo.areaThree.title;
                areaContent.innerText = areaInfo.areaThree.content;
                break;
              default:
                break;
            }
          }
        });
      }

      // 区域光效
      let test = function (lightWall) {
        if (!that.isAreaOne) {
          let shaderMaterial = Global.setLightWall(that.textureImg.yellowUrl, that.yellow, lightWall);
          lightWall.meshes.forEach(item => item.material = shaderMaterial);
          that.isAreaOne = true;
        }
      };
    },
    // 显示报警信息
    showAlarm() {
      // 报警图标
      let planeArr = [];

      let alarmPosition = [];
      let circlePosition = [];
      let warnPoints = Global.schoolEquipment.outside.filter(equipment => equipment.type === "warn")

      for (let item of warnPoints) {
        alarmPosition.push(new BABYLON.Vector3(item.position[0], item.position[1], item.position[2]));
        circlePosition.push(new BABYLON.Vector3(item.position[0], item.position[1] + 20.0, item.position[2]));
      }

      let plane = BABYLON.MeshBuilder.CreatePlane("planeAlarm", {height: 84 / 4, width: 86.5 / 4});
      const disc = BABYLON.MeshBuilder.CreateDisc("disc", {radius: 30.0});

      for (let i = 0; i < alarmPosition.length; i++) {
        let plane_ = plane.clone("planeAlarm_" + i);

        let materialPlane = new BABYLON.StandardMaterial("Mat", scene);
        materialPlane.diffuseTexture = new BABYLON.Texture(require("../assets/image/icon/alarm.png"), scene);
        materialPlane.diffuseTexture.hasAlpha = true;
        materialPlane.backFaceCulling = true;
        materialPlane.ambientColor = new BABYLON.Color3(1, 1, 1);
        materialPlane.disableLighting = true;

        plane_.material = materialPlane;
        //Add text to dynamic texture
        // plane_.visibility = 0.0
        plane_.billboardMode = 7;
        plane_.position = alarmPosition[i];

        // plane_.position.z -= (plane_.getBoundingInfo().maximum.x - plane_.getBoundingInfo().minimum.x) / 2
        plane_.position.y += (plane_.getBoundingInfo().maximum.y - plane_.getBoundingInfo().minimum.y) / 2 + 20.0;
        plane_.enablePointerMoveEvents = true;

        let disc_ = disc.clone("discAlarm_" + i);
        disc_.position = circlePosition[i];
        // disc_.position.y += 20.0

        disc_.rotation = new BABYLON.Vector3(Math.PI / 2, 0.0, 0.0);
        disc_.material = Global.setSpreadCircle()
        planeArr.push(plane_, disc_);
      }
      plane.dispose();
      disc.dispose();
      alarmPlane = planeArr;
      Global.alarmPlane = alarmPlane;
      Global.alarmPlane.forEach(item => item.setEnabled(false));

      // 绑定鼠标监听事件
      pointerObserver.two = scene.onPointerObservable.add((pointerInfo) => {
        let eventType = pointerInfo.event.type;
        if (!pointerInfo.pickInfo.pickedMesh) {
          return;
        }
        let meshName = pointerInfo.pickInfo.pickedMesh.name;
        if (!meshName.match("planeAlarm_")) {
          return;
        }
        if (pointerInfo.event.button === 0 && eventType === "pointerdown") {
          // 消除弹窗已有位置的影响
          this.chosePoint = null;
          this.alarmChosePoint = pointerInfo.pickInfo.pickedPoint;
          switch (meshName) {
            case "planeAlarm_0":
              this.isAlarmPopInfo = true;
              this.alarmInfo = warnPoints[0]
              break;
            case "planeAlarm_1":
              this.isAlarmPopInfo = true;
              this.alarmInfo = warnPoints[1]
              break;
            default:
              break;
          }
        }
      });
    },
    // 水面流动效果
    waterFlow() {
      let gltf = hiScene.objects.filter(item => item.type === "gltf");
      let waterMesh = hiScene.findObjectByName("水面");
      if (!waterMesh) {
        return;
      }

      let reflectGltf = [];
      gltf.forEach(item => {
        if (item.id !== waterMesh.id) {
          reflectGltf.push(item.id);
        }
      });
      let ground = hiScene.objects.filter(item => item.name === "中医药地面05_草地_0225")

      let waterMat = {
        "type": "water",
        "renderObjects": [ground[0].id],
        "renderSkybox": true,
        "bumpTexture": require("../assets/image/bg/waterbump.png"),
        "bumpHeight": 1
      };

      waterMesh.customMaterial = new HiWaterMaterial(hiScene, waterMat);
      waterMesh.customMaterial.backFaceCulling = true;
      waterMesh.customMaterial.bumpHeight = 0.1;
      waterMesh.customMaterial.waveHeight = 0.5;
      waterMesh.customMaterial.windForce = 5;
      waterMesh.customMaterial._material.waveLength = 2;
      waterMesh.customMaterial.waterColor = [73.0 / 255.0, 167.0 / 255.0, 255.0 / 255.0];
      waterMesh.customMaterial.colorBlendFactor = 0.5;
    },
    // 加载动画
    loadAnimation() {
      let scene = window.scene;
      let that = this;
      this.isAnimation = !this.isAnimation;
      if (this.observer) {
        scene.onBeforeRenderObservable.remove(this.observer);
      }
      if (this.isAnimation) {
        const slide = function (turn, dist) { //after covering dist apply turn
          this.turn = turn;
          this.dist = dist;
        };
        const track = [];
        track.push(new slide(Math.PI / 4, 200));
        BABYLON.SceneLoader.ImportMeshAsync("",
            "https://10.32.102.170:28081/model/model/", "AWACS.glb", scene).then((result) => {
          if (!result) {
            return;
          }
          this.plane = result.meshes[0];
          this.plane.scaling = new BABYLON.Vector3(0.5, 0.5, 0.5);
          this.plane.position = new BABYLON.Vector3(-391.87142839832205, 110.01344571117454, 142.59623402903492);
          this.plane.rotation = new BABYLON.Vector3(0.0, Math.PI / 2, 0.0);

          let distance = 0;
          let step = 0.5;
          let p = 0;
          pointerObserver.observer = scene.onBeforeRenderObservable.add(function () {
            that.plane.movePOV(0, 0, -step);
            distance += step;
            camera.target = that.plane.position;
            camera.position = new BABYLON.Vector3(that.plane.position.x - 50.0, that.plane.position.y + 20.0, that.plane.position.z);
            scene.activeCamera.panningSensibility = 0;

            if (distance > track[p].dist) {
              that.plane.rotate(BABYLON.Axis.Y, BABYLON.Tools.ToRadians(track[p].turn), BABYLON.Space.LOCAL);
              p += 1;
              p %= track.length;
              if (p === 0) {
                distance = 0;
                that.plane.position = new BABYLON.Vector3(-391.87142839832205, 110.01344571117454, 142.59623402903492);
                that.plane.rotation = new BABYLON.Vector3(0.0, Math.PI / 2, 0.0);
              }
            }
          });
        });
      } else {
        this.plane.dispose();
        scene.onBeforeRenderObservable.remove(observer);
        camera.target = new BABYLON.Vector3(0.0, 0.0, -300.0);
        camera.position = new BABYLON.Vector3(-1108.655439013544, 459.2201188381078, -300.00000000000014);
        scene.activeCamera.panningSensibility = 30;
      }
    },
    // 汽车a行驶动画
    carAnimation() {
      // animations方式
      const frameRate = 10;
      const xSlide = new BABYLON.Animation("xSlide", "position", frameRate, BABYLON.Animation.ANIMATIONTYPE_VECTOR3, BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);
      const keyFrames = [];

      let pointData = [
        [222.83288626707943, 108.75973884023131, 537.517942238699],
        [182.605304346298, 106.8431624933367, 540.0167039182894],
        [142.16656297044864, 105.24973273114034, 543.8967516323322],
        [99.53710561254954, 103.84659598966198, 546.1514859808615],
        [55.702821882961544, 102.57544013065564, 548.6277904652685],
        [5.262884136975458, 101.11181710970652, 552.7511954925237],
        [-39.935162878581465, 99.7999022043004, 557.0059359416625],
        [-75.8934450304817, 98.75613505675881, 560.4387954520055],
        [-110.24520257215707, 97.96431583805975, 562.9296727272242],
        [-143.97861488019748, 97.96310243777481, 565.3021189348085],
        [-174.6490826215806, 97.95294952392578, 566.7347570475529],
        [-205.19290506566944, 97.95294952392584, 568.1377625252978],
        [-233.35415359949104, 97.95294952392578, 569.0913602957849],
        [-263.6341445337824, 97.95294952392578, 570.567321007733],
        [-295.7897174262086, 97.95294952392584, 573.7486884594979],
        [-324.66341287847, 97.95294952392578, 574.6644080218296],
        [-355.05935356402426, 97.95294952392572, 574.0025679558147],
        [-381.5159035941964, 97.95294952392578, 575.4592671207879],
        [-411.5694953679033, 97.95294952392575, 576.1918002275775],
        [-444.192299699178, 97.95294952392581, 574.5446693875323]
      ];
      // 点位信息
      for (let i = 0; i < pointData.length; i++) {
        keyFrames.push({
          frame: i * frameRate,
          value: new BABYLON.Vector3(pointData[i][0], pointData[i][1], pointData[i][2])
        });
      }
      xSlide.setKeys(keyFrames);

      const car_a = hiScene.findObjectByName("car_a");
      if (!car_a) {
        return;
      }
      // 加载动画
      car_a.bNode.position = keyFrames[0].value;

      // car_a.bNode.rotation = new BABYLON.Vector3(0, 225 * Math.PI / 180, 0)
      car_a.bNode.animations.push(xSlide);
      animationArr.car = scene.beginAnimation(car_a.bNode, 0, (keyFrames.length - 1) * frameRate, true);
    },
    // 公交车行驶动画
    busAnimation() {
      let car_b = hiScene.findObjectByName("公交车");
      if (!car_b) {
        return;
      }
      const frameRate = 10;
      // 运行轨迹
      const xSlide = new BABYLON.Animation("xSlide", "position", frameRate, BABYLON.Animation.ANIMATIONTYPE_VECTOR3, BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);
      const keyFrames = [];
      let pointData = [
        [-79.46688039261073, 81.06927890641042, 540.2598772398064],
        [-100.00229253388574, 85.2910463162669, 481.7663323272289],
        [-134.64478247884364, 85.3026072496373, 434.346562848194],
        [-170.81060565237306, 85.22384760166533, 399.2464590610133],
        [-236.84793953700535, 85.3416677508879, 350.9902984253679],
        [-288.74102508906344, 85.54178492836195, 310.1516972713728],
        [-329.4010813793142, 85.42569914910592, 278.6747332809118],
        [-369.3195079458519, 85.60211203914218, 247.22464315558085],

        [-394.27551370277934, 85.44725297082555, 216.4626159085762],
        [-394.27551370277934, 85.44725297082555, 216.4626159085762],
        [-394.27551370277934, 85.44725297082555, 216.4626159085762],
        [-394.27551370277934, 85.44725297082555, 216.4626159085762],
        [-394.27551370277934, 85.44725297082555, 216.4626159085762],
        [-394.27551370277934, 85.44725297082555, 216.4626159085762],

        [-410.0827588131941, 85.31161515570886, 189.52617518974301],
        [-425.7453426357031, 85.48322317960303, 149.10736732101006],
        [-432.8444038850605, 85.5856574451682, 114.80582808168396],
        [-432.96557069725355, 85.88878143958115, 85.01625440370961],
        [-433.3522746178802, 86.2201169604654, 47.971057878150546],
        [-428.44853722368185, 86.69997843524234, -5.744481925506566],
        [-423.3159886718895, 86.69997773069763, -58.81752543156607],
        [-422.36375105886555, 86.69997518254965, -92.20928823406656],
        [-418.39746944064143, 86.69997406005861, -134.5267802933008],
        [-415.0219085509126, 86.69997406005861, -180.15837581839827],
        [-410.38430398743753, 86.69997406005858, -231.74754881531322],
        [-410.81736829387216, 86.69997406005861, -282.15896922594885],
        [-418.1446988076076, 86.69997406005858, -322.4182433359962],
        [-430.2724357045248, 86.69997882569137, -357.6219391204806],
        [-444.05001019895155, 86.70000349436881, -397.3348144610806]
      ];
      for (let i = 0; i < pointData.length; i++) {
        keyFrames.push({
          frame: i * frameRate,
          value: new BABYLON.Vector3(pointData[i][0], pointData[i][1], pointData[i][2])
        });
      }
      xSlide.setKeys(keyFrames);

      // 运行方向
      const xAngle = new BABYLON.Animation("xAngle", "rotation", frameRate, BABYLON.Animation.ANIMATIONTYPE_VECTOR3, BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);
      const angleFrames = [];
      for (let j = 0; j < 1; j++) {
        angleFrames.push({
          frame: j * frameRate,
          value: new BABYLON.Vector3(0, 0, 0)
        });
      }
      for (let j = 1; j < keyFrames.length / 2 - 3; j++) {
        angleFrames.push({
          frame: j * frameRate,
          value: new BABYLON.Vector3(0, Math.PI / 4, 0)
        });
      }
      for (let j = keyFrames.length / 2 + 1; j < keyFrames.length / 2 + 2; j++) {
        angleFrames.push({
          frame: j * frameRate,
          value: new BABYLON.Vector3(0, Math.PI / 10, 0)
        });
      }
      for (let j = keyFrames.length / 2 + 2; j < keyFrames.length - 3; j++) {
        angleFrames.push({
          frame: j * frameRate,
          value: new BABYLON.Vector3(0, 0, 0)
        });
      }
      for (let j = keyFrames.length - 3; j < keyFrames.length; j++) {
        angleFrames.push({
          frame: j * frameRate,
          value: new BABYLON.Vector3(0, Math.PI / 8, 0)
        });
      }

      xAngle.setKeys(angleFrames);
      animationArr.bus = scene.beginDirectAnimation(car_b.bNode, [xSlide, xAngle], 0, (keyFrames.length - 1) * frameRate, true);
    },
    // 云巴运行动画
    skyShuttleAnimation() {
      let car_c = hiScene.findObjectByName("云巴");
      if (!car_c) {
        return;
      }
      const frameRate = 10;
      // 运行轨迹
      const xSlide = new BABYLON.Animation("xSlide", "position", frameRate, BABYLON.Animation.ANIMATIONTYPE_VECTOR3, BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);
      const keyFrames = [];
      let pointData = [
        [228.6025090834098, 101.69998168945301, -1179.992072911853],
        [245.3968227215487, 101.69998168945324, -1122.8477211293969],
        [264.0923794382225, 101.69998168945324, -1059.234197361258],
        [284.8280733156888, 101.69998168945312, -999.6598968961657],
        [305.39018691524996, 101.69998168945312, -946.2260878634444],
        [317.7928094427052, 101.69998168945312, -904.0249095488672],
        [334.54070650625437, 101.69998168945324, -858.0988496560731],
        [352.0568380459653, 101.69998168945301, -798.4984394391466],
        [375.25720544418056, 101.69998168945324, -730.6491806287409],
        [397.88596608530787, 101.69998168945301, -664.7769949848232],
        [417.85491744454384, 101.69998168945312, -602.4049382472415],
        [436.75363826589916, 101.69998168945335, -549.2730378414326],
        [459.75134111320193, 101.69998168945301, -487.84132456515596],
        [485.3099120864276, 101.69998168945301, -406.4987861885204],

        [516.5241028059172, 101.69998168945324, -317.207810519937],
        [516.5241028059172, 101.69998168945324, -317.207810519937],
        [516.5241028059172, 101.69998168945324, -317.207810519937],
        [516.5241028059172, 101.69998168945324, -317.207810519937],
        [516.5241028059172, 101.69998168945324, -317.207810519937],
        [516.5241028059172, 101.69998168945324, -317.207810519937],
        [516.5241028059172, 101.69998168945324, -317.207810519937],
        [516.5241028059172, 101.69998168945324, -317.207810519937],

        [542.4573012018416, 101.69998168945301, -240.28764426272568],
        [568.9699353082992, 104.31937925364377, -157.68176282458427],
        [589.550158021857, 108.23419386934677, -96.22606435883884],
        [617.3599291597696, 113.6991435345385, -10.129041135629564],
        [646.6354601606101, 119.15793268404695, 75.3712852693665],
        [671.7626380911593, 123.91267728618323, 149.96837814545026],
        [696.3720186710864, 128.95142966524043, 229.69473013967905],
        [720.1119558927398, 133.34279812480872, 298.41262138926425],
        [744.7326054346669, 137.98116685536525, 371.14807627825996],
        [771.8854951096935, 142.89866960071686, 447.9100131667145],
        [799.0903098054698, 147.58152527187133, 541.3331216285598]
      ];
      for (let i = 0; i < pointData.length; i++) {
        keyFrames.push({
          frame: i * frameRate,
          value: new BABYLON.Vector3(pointData[i][0], pointData[i][1], pointData[i][2])
        });
      }
      xSlide.setKeys(keyFrames);

      // 运行方向
      const xAngle = new BABYLON.Animation("xAngle", "rotation", frameRate, BABYLON.Animation.ANIMATIONTYPE_VECTOR3, BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);
      const angleFrames = [];
      for (let j = 0; j < keyFrames.length / 2 - 1; j++) {
        angleFrames.push({
          frame: j * frameRate,
          value: new BABYLON.Vector3(0, 0, 0)
        });
      }
      for (let j = keyFrames.length / 2 - 1; j < keyFrames.length; j++) {
        angleFrames.push({
          frame: j * frameRate,
          value: new BABYLON.Vector3(0, -Math.PI / 4, 0)
        });
      }
      xAngle.setKeys(angleFrames);
      animationArr.skyShuttle = scene.beginDirectAnimation(car_c.bNode, [xSlide], 0, (keyFrames.length - 1) * frameRate, true);
    },
    // 开启特效
    openSpecialEffects() {
      this.setSpecialEffects(true);
      // scene.freezeActiveMeshes()
      // console.log("开启特效-场景顶点数", scene.getTotalVertices())
      // console.log("开启特效-场景网格数", scene.getActiveMeshes())
    },
    // 关闭特效
    closeSpecialEffects() {
      // scene.unfreezeActiveMeshes();
      this.setSpecialEffects(false);
      // console.log("关闭特效-场景顶点数", scene.getTotalVertices())
      // console.log("关闭特效-场景网格数", scene.getActiveMeshes())
    },
    setSpecialEffects(boolean) {
      // 隐藏模型
      let lightWall_1 = hiScene.findObjectByName("新一期");
      let lightWall_2 = hiScene.findObjectByName("新二期");
      let lightWall_3 = hiScene.findObjectByName("新三期");
      let lightWall_4 = hiScene.findObjectByName("新四期");
      let car_a = hiScene.findObjectByName("car_a");
      let car_b = hiScene.findObjectByName("公交车");
      let bird = hiScene.findObjectByName("cscs");
      let skyShuttle = hiScene.findObjectByName("云巴");
      let hospital = hiScene.findObjectByName("医院区域");
      let town = hiScene.findObjectByName("长方体");
      let monitorEquipment = hiScene.objects.filter(item => item.type === "gltf" && item.name.match("F-监控"))
      let broadcastEquipment = hiScene.objects.filter(item => item.type === "gltf" && item.name.match("F-广播"))
      if (monitorEquipment.length !== 0) {
        monitorEquipment.forEach(model => {
          model.bNode.setEnabled(boolean);
        });
      }
      if (broadcastEquipment.length !== 0) {
        broadcastEquipment.forEach(model => {
          model.bNode.setEnabled(boolean);
        });
      }
      // 23期建筑
      let twoThree = hiScene.objects.filter(item => item.type === "gltf" && item.name.match("中医药23期"));
      if (twoThree.length !== 0) {
        twoThree.forEach(model => {
          // model.meshes.forEach( mesh => mesh.dispose() )
          model.bNode.setEnabled(boolean);
        });
      }
      // 23期树
      changeTreeArr.forEach(mesh => mesh.isVisible = boolean)
      if (skyShuttle) {
        skyShuttle.bNode.setEnabled(boolean);
      }
      if (lightWall_1) {
        lightWall_1.bNode.setEnabled(boolean);
      }
      if (lightWall_2) {
        lightWall_2.bNode.setEnabled(boolean);
      }
      if (lightWall_3) {
        lightWall_3.bNode.setEnabled(boolean);
      }
      if (lightWall_4) {
        lightWall_4.bNode.setEnabled(boolean);
      }
      areaPlane.forEach(item => item.setEnabled(boolean));
      if (car_a) {
        car_a.bNode.setEnabled(boolean);
      }
      if (car_b) {
        car_b.bNode.setEnabled(boolean);
      }
      if (bird) {
        bird.bNode.setEnabled(boolean);
      }
      if (hospital) {
        hospital.bNode.setEnabled(boolean);
      }
      if (town) {
        town.bNode.setEnabled(boolean);
      }

      // 关闭动画
      if (animationArr.skyShuttle) {
        animationArr.skyShuttle._paused = !boolean;
      }
      if (animationArr.car) {
        animationArr.car._paused = !boolean;
      }
      if (animationArr.bus) {
        animationArr.bus._paused = !boolean;
      }
      if (animationArr.bird) {
        animationArr.bird._paused = !boolean;
      }
      // 开启/关闭阴影
      // hiScene.sceneShadow.enabled = boolean
    },
    disposeBillboard() {
      let billboard = hiScene.objects.filter(item => item.type === "billboard")
      if (billboard.length !== 0) {
        billboard.forEach(model => {
          // console.log(">>>", model.name)
          model._plane.dispose()
          // console.log("isEnabled", model._plane.isEnabled())
        });
      }
    },
    // 医院信息
    showHospitalInfo() {
      let scene = window.scene;
      // 信息框
      let hospitalTitle = document.getElementById("pop_name");
      let hospitalContent = document.getElementById("pop_content");

      let hospital = hiScene.findObjectByName("医院区域");
      if (!hospital) {
        return;
      }

      // 医院区域选中效果纹理
      let hospitalMat = new BABYLON.StandardMaterial("hospitalMat", scene);
      hospitalMat.emissiveColor = new BABYLON.Color3(87.0 / 255.0, 116.0 / 255.0, 219.0 / 255.0);
      hospitalMat.alpha = 0.5;
      hospitalMat.disableLighting = true;

      // 医院区域信息框纹理
      let plane = BABYLON.MeshBuilder.CreatePlane("plane", {height: 186 / 5, width: 495 / 5});
      let materialPlane = new BABYLON.StandardMaterial("Mat", scene);
      materialPlane.diffuseTexture = new BABYLON.Texture(require("../assets/image/building/hospital.png"), scene);
      materialPlane.diffuseTexture.hasAlpha = true;
      materialPlane.backFaceCulling = true;
      materialPlane.ambientColor = new BABYLON.Color3(1, 1, 1);
      materialPlane.disableLighting = true;
      plane.material = materialPlane;
      plane.visibility = 0.0;
      plane.billboardMode = 7;
      plane.position = new BABYLON.Vector3(526.7280125483687, 161.6355764208207, 274.3548453705413);

      // 康养小镇区域选中效果纹理
      let townMat = new BABYLON.StandardMaterial("townMat", scene);
      townMat.emissiveColor = new BABYLON.Color3(139.0 / 255.0, 48.0 / 255.0, 231.0 / 255.0);
      townMat.alpha = 0.4;
      townMat.disableLighting = true;

      let hospitalInfo = {
        mat: hospitalMat,
        plane: plane,
        info: {
          title: "璧山中医院",
          content: "详情："
        }
      };

      hospital.meshes.forEach(mesh => {
        mesh.enablePointerMoveEvents = true;
        mesh.visibility = 0.0;
        mesh.material = hospitalInfo.mat;
      });

      pointerObserver.four = scene.onPointerObservable.add((pointerInfo) => {
        let eventType = pointerInfo.event.type;
        if (!pointerInfo.pickInfo.pickedMesh) {
          hospital.meshes.forEach(mesh => mesh.visibility = 0.0);
          hospitalInfo.plane.visibility = 0.0;
          return;
        }
        let parentMesh = HiBaseObject.FindByBNode(pointerInfo.pickInfo.pickedMesh);
        if (!parentMesh) {
          return;
        }
        if (parentMesh.name === hospital.name) {
          if (eventType === "pointermove") {
            hospital.meshes.forEach(mesh => mesh.visibility = 1.0);
            hospitalInfo.plane.visibility = 1.0;
          }
          if (pointerInfo.event.button === 0 && eventType === "pointerdown") {
            // 消除弹窗已有位置的影响
            this.chosePoint = pointerInfo.pickInfo.pickedPoint;

            hospitalTitle.innerText = hospitalInfo.info.title;
            hospitalContent.innerText = hospitalInfo.info.content;
            // 显示信息弹窗
            this.isPopInfo = true;
          }
        }
      });
    },
    // 康养小镇信息
    showTownInfo() {
      let scene = window.scene;
      // 信息框
      let hospitalTitle = document.getElementById("pop_name");
      let hospitalContent = document.getElementById("pop_content");

      let town = hiScene.findObjectByName("康养小镇区域");
      if (!town) {
        return;
      }

      // 康养小镇区域选中效果纹理
      let townMat = new BABYLON.StandardMaterial("townMat", scene);
      townMat.emissiveColor = new BABYLON.Color3(139.0 / 255.0, 48.0 / 255.0, 231.0 / 255.0);
      townMat.alpha = 0.4;
      townMat.disableLighting = true;

      // 康养小镇区域信息框纹理
      let plane = BABYLON.MeshBuilder.CreatePlane("plane", {height: 186 / 5, width: 495 / 5});
      let materialPlane = new BABYLON.StandardMaterial("Mat", scene);
      materialPlane.diffuseTexture = new BABYLON.Texture(require("../assets/image/building/town.png"), scene);
      materialPlane.diffuseTexture.hasAlpha = true;
      materialPlane.backFaceCulling = true;
      materialPlane.ambientColor = new BABYLON.Color3(1, 1, 1);
      materialPlane.disableLighting = true;
      plane.material = materialPlane;
      plane.visibility = 0.0;
      plane.billboardMode = 7;
      plane.position = new BABYLON.Vector3(966.3049779183054, 168.11823301576987, 64.43284239515765);

      let townInfo = {
        mat: townMat,
        plane: plane,
        info: {
          title: "璧山康养小镇",
          content: "详情："
        }
      };

      town.meshes.forEach(mesh => {
        mesh.enablePointerMoveEvents = true;
        mesh.visibility = 0.0;
        mesh.material = townInfo.mat;
      });

      pointerObserver.five = scene.onPointerObservable.add((pointerInfo) => {
        let eventType = pointerInfo.event.type;
        if (!pointerInfo.pickInfo.pickedMesh) {
          town.meshes.forEach(mesh => mesh.visibility = 0.0);
          townInfo.plane.visibility = 0.0;
          return;
        }
        let parentMesh = HiBaseObject.FindByBNode(pointerInfo.pickInfo.pickedMesh);
        if (!parentMesh) {
          return;
        }
        if (parentMesh.name === town.name) {
          if (eventType === "pointermove") {
            town.meshes.forEach(mesh => mesh.visibility = 1.0);
            townInfo.plane.visibility = 1.0;
          }
          if (pointerInfo.event.button === 0 && eventType === "pointerdown") {
            // 消除弹窗已有位置的影响
            this.chosePoint = pointerInfo.pickInfo.pickedPoint;
            hospitalTitle.innerText = townInfo.info.title;
            hospitalContent.innerText = townInfo.info.content;
            // 显示信息弹窗
            this.isPopInfo = true;
          }
        }
      });
    },
    // 显示建筑信息
    showBuildingInfo() {
      // 初始化信息
      let scene = window.scene;
      let that = this;
      let buildingNum = "";
      let buildingName = "";
      let parentMesh = null;
      let gltf = null
      let gltfGlass = null

      // 设置教学楼名称平面
      let buildingPlane = [];
      for (let index in buildingLabel) {
        let plane = BABYLON.MeshBuilder.CreatePlane("buildingPlane_" + index,
            {height: buildingLabel[index].height * 2.0, width: buildingLabel[index].width * 2.0});
        // 位置
        plane.position = buildingLabel[index].position;
        plane.position.z -= (plane.getBoundingInfo().maximum.x - plane.getBoundingInfo().minimum.x) / 2 - 10.0;
        plane.position.y += (plane.getBoundingInfo().maximum.y - plane.getBoundingInfo().minimum.y) / 2;
        plane.position.x -= 10.0;
        // 纹理
        let materialPlane = new BABYLON.StandardMaterial("Mat", scene);
        materialPlane.diffuseTexture = new BABYLON.Texture(buildingLabel[index].url, scene);
        materialPlane.diffuseTexture.hasAlpha = true;
        materialPlane.backFaceCulling = true;
        materialPlane.ambientColor = new BABYLON.Color3(1, 1, 1);
        materialPlane.disableLighting = true;

        plane.material = materialPlane;
        //Add text to dynamic texture
        plane.visibility = 0.0;
        plane.billboardMode = 7;
        plane.enablePointerMoveEvents = true;
        buildingPlane.push(plane);
      }
      // 模型初始化
      let building = hiScene.objects.filter(item => {
        if (item.name.match("一期") && item.name.match("号楼")) {
          return !item.name.match("F");
        } else {
          return false;
        }
      });
      building.forEach(model => {
        model.meshes.forEach(mesh => {
          // 开启模型边缘渲染
          mesh.enableEdgesRendering();
          mesh.edgesWidth = 0.0;
          // 开启鼠标检测
          mesh.enablePointerMoveEvents = true;
        });
      });
      // 鼠标监听事件
      pointerObserver.six = scene.onPointerObservable.add((pointerInfo) => {
        let eventType = pointerInfo.event.type;
        if (!pointerInfo.pickInfo.pickedMesh) {
          if (gltf) {
            gltf.meshes.forEach(item => item.edgesWidth = 0.0);
          }
          buildingPlane.forEach(item => item.visibility = 0.0);
          gltfGlass = null;
          return;
        }
        // 区分选中的模型是教学楼还是信息框
        if (pointerInfo.pickInfo.pickedMesh.name.match("buildingPlane_")) {
          parentMesh = pointerInfo.pickInfo.pickedMesh;
        } else {
          parentMesh = HiBaseObject.FindByBNode(pointerInfo.pickInfo.pickedMesh);
        }
        if (!parentMesh) {
          return;
        }
        // 不属于教学楼监听的鼠标事件到这结束
        if (!(parentMesh.name.match("一期") && parentMesh.name.match("号楼")) && !parentMesh.name.match("buildingPlane_")) {
          return;
        }
        if (parentMesh.name.match("一期") && parentMesh.name.match("号楼") && !parentMesh.name.match("F")) {
          if (eventType === "pointermove") {
            if (gltf) {
              gltf.meshes.forEach(item => item.edgesWidth = 0.0);
            }
            buildingPlane.forEach(item => item.visibility = 0.0);
            let index = parentMesh.name.lastIndexOf("_");
            if (index > 0) {
              if (parentMesh.name.slice(index + 1, parentMesh.name.length) === "玻璃") {
                let gltfName = parentMesh.name.slice(0, index);
                gltf = hiScene.findObjectByName(gltfName);
                gltfGlass = hiScene.findObjectByName(parentMesh.name);
                parentMesh = gltf;
              }
            } else {
              gltf = hiScene.findObjectByName(parentMesh.name);
              let glassName = parentMesh.name + "_" + "玻璃";
              gltfGlass = hiScene.findObjectByName(glassName);
            }
            // 外壳高亮
            gltf.meshes.forEach(item => {
              item.edgesWidth = 40.0;
              item.edgesColor = new BABYLON.Color4(255.0 / 255.0, 0.0 / 255.0, 0.0 / 255.0, 1.0);
            });
            switch (gltf.name) {
              case "一期一号楼": {
                buildingPlane[0].visibility = 1.0
                buildingNum = 0;
                buildingName = "中医";
                break;
              }
              case "一期二号楼": {
                buildingPlane[1].visibility = 1.0
                buildingNum = 1;
                buildingName = "针灸推拿";
                break;
              }
              case "一期三号楼": {
                buildingPlane[2].visibility = 1.0
                buildingNum = 2;
                buildingName = "中西医结合";
                break;
              }
              case "一期四号楼": {
                buildingPlane[3].visibility = 1.0
                buildingNum = 3;
                buildingName = "中药";
                break;
              }
              case "一期五号楼": {
                buildingPlane[4].visibility = 1.0
                buildingNum = 4;
                buildingName = "图书馆、标本馆、综合教学楼";
                break;
              }
              case "一期六号楼": {
                buildingPlane[5].visibility = 1.0
                buildingNum = 5;
                buildingName = "行政综合楼";
                break;
              }
              case "一期七号楼": {
                buildingPlane[6].visibility = 1.0
                buildingNum = 6;
                buildingName = "学术会议中心";
                break;
              }
              case "一期八号楼": {
                buildingPlane[7].visibility = 1.0
                buildingNum = 7;
                buildingName = "体育馆";
                break;
              }
              case "一期九号楼": {
                buildingPlane[8].visibility = 1.0
                buildingNum = 8;
                buildingName = "食堂";
                break;
              }
              case "一期十号楼": {
                buildingPlane[9].visibility = 1.0
                buildingNum = 9;
                buildingName = "宿舍S1~4";
                break;
              }
              case "一期十一号楼": {
                buildingPlane[10].visibility = 1.0
                buildingNum = 10;
                buildingName = "宿舍S5~7";
                break;
              }
            }
          }
          if (pointerInfo.event.button === 0 && eventType === "pointerdown") {
            if (this.$route.name === "Msite" || this.$route.name === "Show") {
            } else {
              Global.Func.choseBuilding(buildingName);
            }
          }
        }
        if (parentMesh.name.match("buildingPlane_") && pointerInfo.event.button === 0 && eventType === "pointerdown") {
          this.chosePoint = this.planePosition[buildingNum];
          if (this.$route.name === "Msite" || this.$route.name === "Show") {
            Global.flyTo(Global.buildingPos[buildingName]);
            this.buildingName = buildingTextInfo[buildingNum].name;
            this.buildingContent = buildingTextInfo[buildingNum].content
            buildingPlane.forEach(item => item.visibility = 0.0);
            // 显示信息弹窗
            that.isPopInfo = true;
          }
        }
      });
    },
    // 关闭弹窗
    closePop() {
      this.isPopInfo = false;
      if (this.isAreaOne) {
        let lightWall_1 = hiScene.findObjectByName("新一期");
        let lightWall_2 = hiScene.findObjectByName("新二期");
        let lightWall_3 = hiScene.findObjectByName("新三期");
        // 初始化光墙
        let lightWallArr = [lightWall_1, lightWall_2, lightWall_3];
        lightWallArr.forEach(lightWall => {
          if (lightWall.meshes) {
            let shaderMaterial = Global.setLightWall(this.textureImg.blueUrl, this.blue, lightWall);
            lightWall.meshes.forEach(item => item.material = shaderMaterial);
          }
        });
        this.isAreaOne = false;
      }
    },
    getPosition() {
      let scene = window.scene;
      let camera = window.camera
      const position_1 = camera.position
      const target = camera.target
      console.log("position", position_1)
      console.log("target", target)
      return
      const pickResult = scene.pick(scene.pointerX, scene.pointerY);
      console.log('pickResult', pickResult.pickedMesh)
      const position = [
        pickResult.pickedPoint._x,
        pickResult.pickedPoint._y,
        pickResult.pickedPoint._z
      ];
      console.log("position", position);
    },
    changeCameraTarget() {
      scene.onPointerObservable.add((pointerInfo) => {
        camera.target = pointerInfo.pickInfo.pickedPoint
      }, BABYLON.PointerEventTypes.POINTERDOWN)
    },
    // 获取建筑模型的位置
    getBuildingPos() {
      let buildingName = ""
      let buildingArr = hiScene.objects.filter(item => item.name.match("一期") && item.name.match("号楼") && !item.name.match("_"))
      buildingArr.forEach(building => {
        // 模型的中心位置
        let bounds = building.meshes[0].getHierarchyBoundingVectors();
        let modelCenter = bounds.min.add(bounds.max);
        modelCenter = new BABYLON.Vector3(modelCenter.x / 2, modelCenter.y / 2, modelCenter.z / 2);
        Global.buildingArr.push(building.name)
        switch (building.name) {
          case "一期一号楼": {
            buildingName = "中医";
            break;
          }
          case "一期二号楼": {
            buildingName = "针灸推拿";
            break;
          }
          case "一期三号楼": {
            buildingName = "中西医结合";
            break;
          }
          case "一期四号楼": {
            buildingName = "中药";
            break;
          }
          case "一期五号楼": {
            buildingName = "图书馆、标本馆、综合教学楼";
            break;
          }
          case "一期六号楼": {
            buildingName = "行政综合楼";
            break;
          }
          case "一期七号楼": {
            buildingName = "学术会议中心";
            break;
          }
          case "一期八号楼": {
            buildingName = "体育馆";
            break;
          }
          case "一期九号楼": {
            buildingName = "食堂";
            break;
          }
          case "一期十号楼": {
            buildingName = "宿舍S1~4";
            break;
          }
          case "一期十一号楼": {
            buildingName = "宿舍S5~7";
            break;
          }
        }
        Global.buildingPos[buildingName].target = modelCenter
        Global.buildingPos[buildingName].position = {
          x: modelCenter._x - 200.0,
          y: modelCenter._y + 100.0,
          z: modelCenter._z,
        }
        // Global.overlooking[buildingName].target = modelCenter
        // Global.overlooking[buildingName].position = {
        //   x: modelCenter._x,
        //   y: modelCenter._y + 260.0,
        //   z: modelCenter._z,
        // }
      })
    }
  }
};
</script>

<style lang="less" scoped>
.showFrames {
  width: 400px;
  height: 200px;
  //background-color: red;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

.building_label {
  width: 163px;
  height: 61.5px;
  //background-image: url("../assets/image/building/building_label.png");
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.mask3 {
  position: fixed;
  left: 0;
  top: 0;
  //width: 100%;
  //height: 100%;
  background-color: rgba(255, 255, 255, 0.0);
  z-index: 999;
}

.pop_info {
  position: absolute;
  left: 0;
  top: 0;
  width: calc(471.78px * 3 / 4);
  height: calc(291px * 3 / 4);
  background-image: url("../assets/image/bg/building_info.png");
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;

  .close-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    background-image: url("../assets/image/bg/building_close.png");
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    cursor: pointer;
  }

  .title {
    width: 100%;
    height: 40px;

    .text {
      //position: absolute;
      //width: 72px;
      height: 30px;
      padding-left: 25px;
      padding-top: 10px;
      font-family: 'FZZhengCu';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      color: #7D5E0E;
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 40px);
    padding: 5px 25px;

    .content_ {
      width: 100%;
      height: 25px;
      margin-bottom: 10px;

      .text {
        //padding: 5px 10px 0 25px;
        font-family: 'FZHTJW';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #FAFAFA;
      }
    }
  }
}

.alarm_pop_info:extend(.pop_info) {
  background-image: url("../assets/image/bg/alarm_pop.png");

  .close-icon:extend(.pop_info .close-icon) {
    background-image: url("../assets/image/bg/alarm_close.png");
  }

  .title:extend(.pop_info .title) {
    .text:extend(.pop_info .title .text) {
      color: #7D0E0E;
    }
  }

  .content:extend(.pop_info .content) {
    .content_:extend(.pop_info .content .content_) {
      .text:extend(.pop_info .content .content_ .text) {
      }
    }
  }
}

.chose_move {
  position: fixed;
  left: 200px;
  top: 200px;
  width: 100px;
  height: 50px;
  display: flex;

  .hospital {
    width: 40px;
    height: 100%;
    background-color: green;
  }

  .teaching {
    width: 40px;
    height: 100%;
    margin-left: 20px;
    background-color: blue;
  }
}

.mask2 {
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-image: url(../assets/image/bg/Group30.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.bar {
  height: 100%;
  background-color: #1EBCBF;
}

.bar_label {
  position: absolute;
  bottom: -30px;
  color: #fff;
  font-size: 16px;
}

.jingdu {
  position: absolute;
  width: 471.5px;
  height: 23.5px;
  background-color: #fff;
  box-shadow: inset 5px 5px 5px #a8a8a8;
  left: 50%;
  margin-left: -236px;
  bottom: -60px;
}

.loading_c {
  width: 100%;
  height: 100%;
  background-image: url(../assets/image/bg/out.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -105.75px;
  margin-top: -99px;
}

.loading_up {
  width: 100%;
  height: 100%;
  background-image: url(../assets/image/bg/up.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -105.75px;
  margin-top: -99px;
}

.loading_w {
  width: 148px;
  height: 148px;
  background-image: url(../assets/image/bg/wriling.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  animation: whirling 1s linear infinite;
}

@keyframes whirling {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.container {
  width: 100%;
  height: 100%;
  position: relative;
}

#renderCanvas {
  width: 100%;
  height: 100%;
}

.mid {
  width: 211.5px;
  height: 198px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mask {
  width: 100%;
  height: 100%;
  background-image: url(../assets/image/bg/loading.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
}
</style>
