"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HiScene = void 0;
const hiobject_1 = require("./hiobject");
const hiskybox_1 = require("./hiskybox");
const hiSceneEnvTexture_1 = require("./hiSceneEnvTexture");
const lights_1 = require("./lights");
const shadow_1 = require("./shadow");
const hiobject_loader_1 = require("./hiobject_loader");
const defaultHiSceneData = {
    version: 1,
    LLH: [0, 0, 0],
    objects: [],
};
/**
 * 场景
 */
class HiScene {
    constructor(scene, data, baseUrl) {
        this._LLH = [0, 0, 0];
        this._scene = scene;
        {
            this._root = new hiobject_1.HiBaseObject(this);
            this._root.name = "root";
        }
        this._LLH = (data === null || data === void 0 ? void 0 : data.LLH) || defaultHiSceneData.LLH;
        this._baseUrl = baseUrl;
        // 解析场景光线
        this._sceneLight = new lights_1.HiSceneLight(this, data === null || data === void 0 ? void 0 : data.sceneLight);
        // 解析场景阴影
        this._sceneShadow = new shadow_1.HiSceneShadow(this, data === null || data === void 0 ? void 0 : data.sceneShadow);
    }
    static FromData(scene, data, rootUrl) {
        let hiScene = new HiScene(scene, data, rootUrl);
        // 解析天空盒
        if (data.skybox) {
            hiScene._skybox = (0, hiskybox_1.parseSkyBoxData)(hiScene, data.skybox);
        }
        // 解析环境光贴图
        if (data.envTexture) {
            hiScene._envTexture = new hiSceneEnvTexture_1.HiSceneEnvTexture(hiScene, data.envTexture);
        }
        // 解析objects
        for (const objData of data.objects) {
            let obj = (0, hiobject_loader_1.traverseLoadHiObjectFromData)(hiScene, objData);
            obj.setParent(hiScene.root);
        }
        return hiScene;
    }
    static FromDataUrl(scene, dataUrl) {
        return __awaiter(this, void 0, void 0, function* () {
            dataUrl = new URL(dataUrl, window.location.href).href;
            let rootUrl = new URL(".", dataUrl).href;
            let data = yield (yield fetch(dataUrl)).json();
            return HiScene.FromData(scene, data, rootUrl);
        });
    }
    get objects() {
        return this.root.children;
    }
    get bScene() {
        return this._scene;
    }
    get baseUrl() {
        return this._baseUrl;
    }
    get envTexture() {
        return this._envTexture;
    }
    removeEnvTexture() {
        if (this._envTexture) {
            this._envTexture.dispose();
            this._envTexture = undefined;
        }
    }
    setEnvTextureData(data) {
        this.removeEnvTexture();
        this._envTexture = new hiSceneEnvTexture_1.HiSceneEnvTexture(this, data);
    }
    get root() {
        return this._root;
    }
    get skybox() {
        return this._skybox;
    }
    removeSkyBox() {
        if (this._skybox) {
            this._skybox.dispose();
            this._skybox = undefined;
        }
    }
    setSkyBoxData(data) {
        this.removeSkyBox();
        this._skybox = (0, hiskybox_1.parseSkyBoxData)(this, data);
    }
    get sceneLight() {
        return this._sceneLight;
    }
    get sceneShadow() {
        return this._sceneShadow;
    }
    toData() {
        var _a, _b;
        return {
            version: 1,
            LLH: this._LLH,
            objects: this.objects.map(child => child.toData()),
            skybox: (_a = this._skybox) === null || _a === void 0 ? void 0 : _a.toData(),
            envTexture: (_b = this._envTexture) === null || _b === void 0 ? void 0 : _b.toData(),
            sceneLight: this._sceneLight.toData(),
            sceneShadow: this._sceneShadow.toData(),
        };
    }
    /**
    * 根据id查找对象
    * @param id
    * @returns
    */
    findObjectById(id) {
        let rt;
        this.root.traverse((obj) => {
            if (!rt && obj.id === id)
                rt = obj;
        });
        return rt;
    }
    /**
     * 根据名称查找对象
     * @param name
     * @returns
     */
    findObjectByName(name) {
        let rt;
        this.root.traverse((obj) => {
            if (!rt && obj.name === name)
                rt = obj;
        });
        return rt;
    }
    /**
     * 根据名称查找对象
     * @param name
     * @returns
     */
    findObjectsByName(name) {
        let rt = [];
        this.root.traverse((obj) => {
            if (obj.name === name)
                rt.push(obj);
        });
        return rt;
    }
    /**
     * 根据鼠标当前点位拾取HiObject
     * @returns
     */
    pickHiObject() {
        let res = this._scene.pick(this._scene.pointerX, this._scene.pointerY);
        return hiobject_1.HiBaseObject.FindByBNode(res === null || res === void 0 ? void 0 : res.pickedMesh);
    }
}
exports.HiScene = HiScene;
