"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assertIsDefined = exports.assertIsNumber = exports.assertIsString = exports.assert = void 0;
function assert(expr, msg) {
    if (!expr)
        throw new Error(msg !== null && msg !== void 0 ? msg : "Assert判定失败");
}
exports.assert = assert;
function assertIsString(value, msg) {
    if (typeof value !== "string")
        throw new Error(msg !== null && msg !== void 0 ? msg : "Not a string");
}
exports.assertIsString = assertIsString;
function assertIsNumber(value, msg) {
    if (typeof value !== 'number')
        throw Error(msg !== null && msg !== void 0 ? msg : 'Not a number');
}
exports.assertIsNumber = assertIsNumber;
function assertIsDefined(value, msg) {
    if (value === undefined || value === null) {
        throw new Error(msg !== null && msg !== void 0 ? msg : `${value} is not defined`);
    }
}
exports.assertIsDefined = assertIsDefined;
