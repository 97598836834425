"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HiBillboardSystem = exports.getDefaultHiBillboardData = void 0;
const BABYLON = __importStar(require("@babylonjs/core/Legacy/legacy"));
const hiobject_1 = require("./hiobject");
const url_1 = require("./url");
function getDefaultHiBillboardData() {
    return Object.assign(Object.assign({}, (0, hiobject_1.getDefaultHiBaseObjectData)()), { type: "billboard", url: "", center: [0, 0, 0], positions: [], scalings: [], ambientColor: [1, 1, 1] });
}
exports.getDefaultHiBillboardData = getDefaultHiBillboardData;
/**
 * Billboard过多时会消耗大量CPU资源，数量达到千时应使用HiFastBillboard
 * 注意
 * @see HiFastBillboardSystem
 */
class HiBillboardSystem extends hiobject_1.HiBaseObject {
    constructor(hiScene, data) {
        super(hiScene, data);
        this._instances = [];
        this._type = "billboard";
        let scene = hiScene.bScene;
        // 数据检查和添加默认值
        if (!data.url)
            throw Error(`billboard的url无效: '${data.url}'`);
        let defaultData = getDefaultHiBillboardData();
        let requiredData = {
            id: this.id,
            children: data.children || defaultData.children,
            name: data.name || defaultData.name,
            position: data.position || defaultData.position,
            rotation: data.rotation || defaultData.rotation,
            scaling: data.scaling || defaultData.scaling,
            type: defaultData.type,
            url: data.url,
            center: data.center || defaultData.center,
            positions: data.positions || defaultData.positions,
            scalings: data.scalings || defaultData.scalings,
            ambientColor: data.ambientColor || defaultData.ambientColor,
        };
        this._url = requiredData.url;
        this._center = BABYLON.Vector3.FromArray(requiredData.center);
        // 构建billboard基础mesh
        let plane = BABYLON.MeshBuilder.CreatePlane("plane", {
            width: 1,
            height: 1,
        }, scene);
        {
            let posData = plane.getVerticesData(BABYLON.VertexBuffer.PositionKind);
            if (!posData)
                throw Error("plane mesh构造失败");
            this._originPosData = posData;
        }
        plane.billboardMode = BABYLON.AbstractMesh.BILLBOARDMODE_ALL;
        plane.parent = this._node;
        plane.setEnabled(false);
        this._plane = plane;
        this._updatePosData();
        var mat = new BABYLON.StandardMaterial("billboard", scene);
        mat.diffuseTexture = new BABYLON.Texture((0, url_1.urlJoin)(data.url, undefined, hiScene.baseUrl), scene);
        mat.diffuseTexture.hasAlpha = true;
        mat.backFaceCulling = true;
        mat.ambientColor = BABYLON.Color3.FromArray(requiredData.ambientColor);
        mat.disableLighting = true;
        plane.material = mat;
        for (let i = 0; i < requiredData.positions.length; i += 3) {
            this.addBillboard({
                position: new BABYLON.Vector3(requiredData.positions[i], requiredData.positions[i + 1], requiredData.positions[i + 2]),
                scaling: new BABYLON.Vector3(requiredData.scalings[i], requiredData.scalings[i + 1], requiredData.scalings[i + 2]),
            });
        }
    }
    toData() {
        let positions = new Array(this.count * 3);
        let scalings = new Array(this.count * 3);
        let i = 0;
        this._instances.forEach(instance => {
            instance.position.toArray(positions, i);
            instance.scaling.toArray(scalings, i);
            i += 3;
        });
        return Object.assign(Object.assign({}, super.toData()), { url: this.url, center: this.center.asArray(), positions: positions, scalings: scalings, ambientColor: this.ambientColor.asArray() });
    }
    get ambientColor() {
        let mat = this._plane.material;
        return mat.ambientColor;
    }
    set ambientColor(v) {
        let mat = this._plane.material;
        mat.ambientColor = v;
    }
    set center(v) {
        this._center.copyFrom(v);
        this._updatePosData();
    }
    /**
     * 获取pivot基准点，billboard会以这个点为中心转动
     * 注意对用get pivot()得到的点修改是无效的，必须调用set pivot()
     */
    get center() {
        return this._center;
    }
    set url(v) {
        this._url = v;
        let mat = this._plane.material;
        let texture = mat.diffuseTexture;
        texture.updateURL(v);
    }
    get url() {
        return this._url;
    }
    get count() {
        return this._instances.length;
    }
    addBillboard(props) {
        let instance = this._plane.createInstance("");
        instance.hiObject = this;
        instance.position = props.position;
        instance.scaling = props.scaling || BABYLON.Vector3.One();
        this._instances.push(instance);
        return instance;
    }
    removeBillboard(instance) {
        let i = this._instances.indexOf(instance);
        if (i >= 0) {
            instance.dispose();
            this._instances.splice(i, 1);
            return true;
        }
        return false;
    }
    removeAllBillboard() {
        this._instances.forEach(m => m.dispose());
        this._instances = [];
    }
    _updatePosData() {
        let newData = new Float32Array(this._originPosData.length);
        for (let i = 0; i < this._originPosData.length; i += 3) {
            newData[i] = this._originPosData[i] - this._center.x;
            newData[i + 1] = this._originPosData[i + 1] - this._center.y;
            newData[i + 2] = this._originPosData[i + 2] - this._center.z;
        }
        this._plane.setVerticesData(BABYLON.VertexBuffer.PositionKind, newData);
    }
}
exports.HiBillboardSystem = HiBillboardSystem;
