"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseSkyBoxData = exports.HiOneImgSkybox = exports.HiSixImgSkybox = void 0;
const hiSceneEnvTexture_1 = require("./hiSceneEnvTexture");
const BABYLON = __importStar(require("@babylonjs/core/Legacy/legacy"));
// 天空盒图片数组
function getImgArray(data) {
    return [
        data.px,
        data.py,
        data.pz,
        data.nx,
        data.ny,
        data.nz,
    ];
}
const defaultData = {
    size: 10000,
    blur: 0,
    exposure: 1.0,
    hdrTexSize: 512,
    ddsPrefiltered: false,
};
class HiSixImgSkybox {
    constructor(scene, data) {
        this._scene = scene;
        if (data.type && data.type !== "6-IMG") {
            throw Error(`type错误：'${data.type}'`);
        }
        this._data = {
            type: "6-IMG",
            px: data.px,
            nx: data.nx,
            py: data.py,
            ny: data.ny,
            pz: data.pz,
            nz: data.nz,
            size: data.size || defaultData.size,
        };
        this._cubeTexture = this._createCubeTexture();
        this._box = createSkyBox(scene.bScene, {
            pbr: false,
            cubeTexture: this._cubeTexture,
            size: this.size,
        });
    }
    toData() {
        return {
            type: this.type,
            px: this.px,
            nx: this.nx,
            py: this.py,
            ny: this.ny,
            pz: this.pz,
            nz: this.nz,
            size: this.size,
        };
    }
    dispose() {
        this._cubeTexture.dispose();
        this._box.dispose();
    }
    _createCubeTexture() {
        return BABYLON.CubeTexture.CreateFromImages(getImgArray(this._data), this._scene.bScene);
    }
    _updateTexture() {
        // 本来想用cubeTexture的updateURL接口更新贴图的，但是无论我怎样markAsDirty都没有更新缓存
        // 手动设置材质是有效的，这可能是当前BABYLONJS版本的BUG
        // this._cubeTexture.updateURL("", undefined, () => {
        //     console.log("load");
        //     this._box.material?.markAsDirty(BABYLON.Material.TextureDirtyFlag)
        //     this._box.markAsDirty()
        // }, undefined, () => {
        //     console.log("error");
        // }, undefined, undefined,
        //     getImgArray(this._data))
        // this._box.material?.markAsDirty(BABYLON.Material.TextureDirtyFlag)
        // this._box.markAsDirty()
        this._cubeTexture.dispose();
        this._cubeTexture = this._createCubeTexture();
        let mat = this._box.material;
        mat.reflectionTexture = this._cubeTexture;
    }
    get type() {
        return this._data.type;
    }
    get size() {
        return this._data.size;
    }
    set size(v) {
        this._data.size = v;
        this._box.scaling.setAll(v);
    }
    get px() {
        return this._data.px;
    }
    set px(v) {
        this._data.px = v;
        this._updateTexture();
    }
    get py() {
        return this._data.py;
    }
    set py(v) {
        this._data.py = v;
        this._updateTexture();
    }
    get pz() {
        return this._data.pz;
    }
    set pz(v) {
        this._data.pz = v;
        this._updateTexture();
    }
    get nx() {
        return this._data.nx;
    }
    set nx(v) {
        this._data.nx = v;
        this._updateTexture();
    }
    get ny() {
        return this._data.ny;
    }
    set ny(v) {
        this._data.ny = v;
        this._updateTexture();
    }
    get nz() {
        return this._data.nz;
    }
    set nz(v) {
        this._data.nz = v;
        this._updateTexture();
    }
}
exports.HiSixImgSkybox = HiSixImgSkybox;
class HiOneImgSkybox {
    constructor(scene, data) {
        this._scene = scene;
        if (data.type !== "1-IMG") {
            throw Error(`type错误：'${data.type}'`);
        }
        this._data = {
            type: "1-IMG",
            size: data.size || defaultData.size,
            blur: data.blur || defaultData.blur,
            exposure: data.exposure || defaultData.exposure,
            url: data.url,
            hdrTexSize: data.hdrTexSize || defaultData.hdrTexSize,
            ddsPrefiltered: data.ddsPrefiltered || defaultData.ddsPrefiltered,
        };
        this._cubeTexture = this._createCubeTexture();
        this._box = createSkyBox(scene.bScene, {
            cubeTexture: this._cubeTexture,
            size: this.size,
            blur: this.blur,
            pbr: true,
        });
        // 用set来初始化
        this.exposure = this.exposure;
    }
    toData() {
        return {
            type: this.type,
            url: this.url,
            size: this.size,
            blur: this.blur,
            exposure: this.exposure,
            hdrTexSize: this.hdrTexSize,
            ddsPrefiltered: this.ddsPrefiltered,
        };
    }
    dispose() {
        this._cubeTexture && this._cubeTexture.dispose();
        this._box && this._box.dispose();
    }
    _createCubeTexture() {
        return (0, hiSceneEnvTexture_1.createCubeTextureForHdr)(this._scene.bScene, {
            url: this.url,
            hdrTexSize: this.hdrTexSize,
            ddsPrefiltered: this.ddsPrefiltered,
        });
    }
    _recreateAndSetCubeTexture() {
        // PROBLEM 这里其实有缓存问题，似乎Babylonjs将url作为id将纹理给缓存了导致更新有问题
        if (this._cubeTexture)
            this._cubeTexture.dispose();
        this._cubeTexture = this._createCubeTexture();
        let mat = this._box.material;
        mat.reflectionTexture = this._cubeTexture;
    }
    get type() {
        return this._data.type;
    }
    get url() {
        return this._data.url;
    }
    set url(v) {
        this._data.url = v;
        this._recreateAndSetCubeTexture();
    }
    get blur() {
        return this._data.blur;
    }
    set blur(v) {
        this._data.blur = v;
        let mat = this._box.material;
        mat.microSurface = 1 - v;
    }
    get size() {
        return this._data.size;
    }
    set size(v) {
        this._data.size = v;
        this._box.scaling.setAll(v);
    }
    get exposure() {
        return this._data.exposure;
    }
    set exposure(v) {
        this._data.exposure = v;
        let mat = this._box.material;
        mat.cameraExposure = v;
    }
    get hdrTexSize() {
        return this._data.hdrTexSize;
    }
    // PROBLEM 因为Babylonjs缓存的问题，不生效
    // set hdrTexSize(v){
    //     this._data.hdrTexSize = v
    //     this._recreateAndSetCubeTexture()
    // }
    get ddsPrefiltered() {
        return this._data.ddsPrefiltered;
    }
}
exports.HiOneImgSkybox = HiOneImgSkybox;
/**
 * 创建天空盒
 * @param scene
 * @param props
 * @param props.pbr 若为true则天空盒的材质为PBRMaterial，否则为StandardMaterial
 * @returns
 */
function createSkyBox(scene, props) {
    let res = scene.createDefaultSkybox(props.cubeTexture, props.pbr, 1, props.blur, false);
    if (!res)
        throw Error("创建天空盒失败");
    res.scaling.setAll(props.size);
    return res;
}
function parseSkyBoxData(hiScene, data) {
    switch (data.type) {
        case "1-IMG":
            return new HiOneImgSkybox(hiScene, data);
            break;
        case "6-IMG":
        case undefined:
        case null:
            return new HiSixImgSkybox(hiScene, data);
            break;
        default:
            throw Error(`type错误，skybox数据为：'${JSON.stringify(data, null, 2)}'`);
            break;
    }
}
exports.parseSkyBoxData = parseSkyBoxData;
