"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HiSceneShadow = void 0;
const BABYLON = __importStar(require("@babylonjs/core/Legacy/legacy"));
class HiSceneShadow {
    constructor(hiScene, data) {
        this._scene = hiScene;
        this._data = {
            // 显示设置为false
            enabled: !((data === null || data === void 0 ? void 0 : data.enabled) === false),
            bias: (data === null || data === void 0 ? void 0 : data.bias) || 0.001,
            mapSize: (data === null || data === void 0 ? void 0 : data.mapSize) || 4096,
        };
        let light = hiScene.sceneLight._light;
        if (hiScene.bScene.getEngine().webGLVersion === 2) {
            let sg = new BABYLON.CascadedShadowGenerator(this._data.mapSize, light);
            sg.lambda = 1;
            sg.autoCalcDepthBounds = true;
            sg.bias = 0.001;
            this._sg = sg;
        }
        else {
            let sg = new BABYLON.ShadowGenerator(this._data.mapSize, light);
            sg.usePoissonSampling = true;
            this._sg = sg;
            // 若不设置这个光线位置不会更新
            light.autoCalcShadowZBounds = true;
        }
        this.enabled = this._data.enabled;
        this.bias = this._data.bias;
    }
    toData() {
        return {
            enabled: this.enabled,
            bias: this.bias,
            mapSize: this.mapSize,
        };
    }
    set enabled(v) {
        this._sg.getLight().shadowEnabled = v;
    }
    get enabled() {
        return this._sg.getLight().shadowEnabled;
    }
    set bias(v) {
        this._sg.bias = v;
    }
    get bias() {
        return this._sg.bias;
    }
    set mapSize(v) {
        this._sg.mapSize = v;
    }
    get mapSize() {
        return this._sg.mapSize;
    }
}
exports.HiSceneShadow = HiSceneShadow;
