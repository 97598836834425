"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HiBaseObject = exports.getRequiredHiBaseObjectData = exports.getDefaultHiBaseObjectData = void 0;
const BABYLON = __importStar(require("@babylonjs/core/Legacy/legacy"));
function getDefaultHiBaseObjectData() {
    return {
        id: "",
        name: "",
        type: "base",
        position: [0, 0, 0],
        rotation: [0, 0, 0],
        scaling: [1, 1, 1],
        children: [],
    };
}
exports.getDefaultHiBaseObjectData = getDefaultHiBaseObjectData;
function getRequiredHiBaseObjectData(data) {
    let defaultData = getDefaultHiBaseObjectData();
    if (!data)
        return defaultData;
    else
        return {
            id: data.id || defaultData.id,
            name: data.name || defaultData.name,
            type: data.type || defaultData.type,
            position: data.position || defaultData.position,
            rotation: data.rotation || defaultData.rotation,
            scaling: data.scaling || defaultData.scaling,
            children: data.children || defaultData.children,
        };
}
exports.getRequiredHiBaseObjectData = getRequiredHiBaseObjectData;
class HiBaseObject {
    constructor(hiScene, data) {
        this._children = [];
        this._id = (data === null || data === void 0 ? void 0 : data.id) || BABYLON.RandomGUID();
        this._type = "base";
        this._scene = hiScene;
        let requiredData = getRequiredHiBaseObjectData(data);
        this._name = requiredData.name;
        this._node = new BABYLON.TransformNode(requiredData.name || "", hiScene.bScene);
        this._node.hiObject = this;
        this._node.position.fromArray(requiredData.position);
        this._node.rotation.fromArray(requiredData.rotation);
        this._node.scaling.fromArray(requiredData.scaling);
    }
    toData() {
        return {
            id: this.id,
            name: this._name,
            type: this.type,
            position: this._node.position.asArray(),
            rotation: this._node.rotation.asArray(),
            scaling: this._node.scaling.asArray(),
            children: this._children.map(child => child.toData()),
        };
    }
    get bNode() {
        return this._node;
    }
    /**
     * 是否在场景中
     */
    get isInScene() {
        return !!this.parent;
    }
    get position() {
        return this._node.position;
    }
    set position(v) {
        this._node.position = v;
    }
    get rotation() {
        return this._node.rotation;
    }
    set rotation(v) {
        this._node.rotation = v;
    }
    get scaling() {
        return this._node.scaling;
    }
    set scaling(v) {
        this._node.scaling = v;
    }
    get id() {
        return this._id;
    }
    get name() {
        return this._name;
    }
    set name(v) {
        this._name = v;
    }
    get type() {
        return this._type;
    }
    /**
     * 设置父节点
     * @param v
     * @param options
     * @param options.keepTransform 是否保持变换姿态。默认为不保持，这样会相对于新的父节点重新计算位置。
     * @returns
     */
    setParent(v, options) {
        if (!options)
            options = { keepTransform: false };
        let keepTransform = !!options.keepTransform;
        // 如果父节点不变，什么都不做
        if (this._parent === v)
            return;
        // 先移除原节点父子关系
        if (this._parent) {
            let i = this._parent.children.indexOf(this);
            if (i === -1)
                throw Error("HiObject内原有的父子关系已经被破坏了");
            else {
                this._parent.children.splice(i, 1);
                this._node.parent = null;
            }
        }
        // 设置新的父子关系
        this._parent = v;
        if (this._parent) {
            this._parent._children.push(this);
            if (keepTransform)
                this._node.setParent(this._parent._node);
            else {
                this._node.parent = this._parent._node;
                this._node.markAsDirty();
            }
        }
    }
    get parent() {
        return this._parent;
    }
    get children() {
        return this._children;
    }
    get meshes() {
        return this._node.getChildMeshes();
    }
    hasChild(object) {
        return object.parent == this;
    }
    /**
     * 前序遍历对象树
     * @param func 遍历操作的函数，参数为当前对象
     */
    traverse(func) {
        func(this);
        this.children.map(child => child.traverse(func));
    }
    /**
     * 查找babylonjs node对应的HiObject
     * @param node
     */
    static FindByBNode(node) {
        if (!node)
            return;
        // @ts-ignore
        else if (node.hiObject)
            // @ts-ignore
            return node.hiObject;
        return HiBaseObject.FindByBNode(node.parent);
    }
}
exports.HiBaseObject = HiBaseObject;
