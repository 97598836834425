<template>
  <div class="header" @click="">
    <div class="header_left">
      <div class="h_icon"></div>
      <div class="h_text"></div>
      <div class="h_date">
        <div class="h_date_">{{ state.time }}</div>
        <div class="h_date_2">
          <span>{{ state.day }}</span>
          <span>{{ state.temperature }}℃</span>
        </div>
      </div>
      <div class="w_icon" id="w_icon"></div>
    </div>
    <div class="header_right">
      <div style="opacity: 0" class="header_right_icon icon1" id="icon1" @click=""></div>
      <div class="header_right_icon icon3" id="icon3" @click="goto('rain')"></div>
      <div class="header_right_icon icon5" id="icon5" @click="goto('snow')"></div>
      <div class="header_right_icon icon4" id="icon4" @click="goto('fog')"></div>
      <div class="header_right_line"></div>
      <div class="header_right_icon icon6" @click="show = !show">
        <div class="jilu_con" v-if="show">
          <div class="jl_close" @click.stop="show = false"></div>
          <div class="jl_title">
            <div class="jl_title_" style="width: 100px;">账号</div>
            <div class="jl_title_" style="width: 230px;">所在板块</div>
            <div class="jl_title_" style="width: 230px;">时间</div>
          </div>
          <div class="jl_list">
            <div class="jl_item" v-for="(item,index) of logs" :key="index">
              <div class="jl_item_" style="width: 100px;">{{ item.user_name }}</div>
              <div class="jl_item_" style="width: 230px;">{{ item.path_name }}</div>
              <div class="jl_item_" style="width: 230px;">{{ item.log_time }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="header_right_icon icon7" @click="logout"></div>
    </div>
    <div class="h_line"></div>
  </div>
</template>

<script>
import Global from "../js/global";
import Message from "@/js/Message";
import axios from "axios";
import HiConfig from "../HiConfig";

let hiScene = Global.hiScene

export default {
  name: "Header",
  data() {
    return {
      imageArr: {
        sun: {
          sunUrl: require("../assets/image/icon/sun.png"),
          sunClickUrl: require("../assets/image/icon/sun_click.png")
        },
        night: {
          nightUrl: require("../assets/image/icon/night.png"),
          nightClickUrl: require("../assets/image/icon/night_click.png")
        },
        rain: {
          rainUrl: require("../assets/image/icon/rain.png"),
          rainClickUrl: require("../assets/image/icon/rain_click.png")
        },
        snow: {
          snowUrl: require("../assets/image/icon/snow.png"),
          snowClickUrl: require("../assets/image/icon/snow_click.png")
        },
        fog: {
          fogUrl: require("../assets/image/icon/fog.png"),
          fogClickUrl: require("../assets/image/icon/fog_click.png")
        }
      },
      weatherMode: {},
      logs: [],
      isSun: true,
      show: false,
      state: {
        time: "",
        day: "",
        temperature: ""
      },
      weatherStyle: {
        sunny: require("../assets/image/weather/sun.png"),
        cloudy: require("../assets/image/weather/cloud.png"),
        rainy: require("../assets/image/weather/rain.png"),
        foggy: require("../assets/image/weather/foggy.png"),
        smog: require("../assets/image/weather/smog.png"),
        snow: require("../assets/image/weather/snow.png")
      }
    };
  },
  mounted() {
    this.initIcon();
    this.getNowTime();
    this.getLogs();
    this.getWeatherData();
    Message.closeSpecialEffect = this.closeSpecialEffect;
  },
  watch: {
    $route: function (newRouter, oldRouter) {
      let name = this.$route.name;
      if (name === 'Show'){
        this.getWeatherData();
      }
    }
  },
  methods: {
    goto(flag){
      this.$router.push({path:'/container/show'})
      if (flag === 'rain'){
        this.rainMode()
      }else  if (flag === 'snow'){
        this.snowMode()
      }else  if (flag === 'fog'){
        this.fogMode()
      }
    },
    logout() {
      this.$confirm("是否退出账户?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        const singleSignOn = "https://cas.cqctcm.edu.cn/logout?service=https://10.32.102.170:13443/";
        window.location.replace(singleSignOn);
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消退出"
        });
      });
    },
    logout_bak() {
      this.$confirm("是否退出账户?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        axios.get("https://10.32.102.170:18095" + "/api/zyy/logout" + `?service=https://10.32.102.170:13443/`).then(res => {
          console.log(res);
          const singleSignOn = "https://cas.cqctcm.edu.cn/oauth2.0/authorize?response_type=code&client_id=lcUWF0RxO5nwdLVjUu&redirect_uri=https://10.32.102.170:13443/";
          window.location.replace(singleSignOn);
        }).catch(err => {
          console.log(err);
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消退出"
        });
      });
    },
    getLogs() {
      let that = this;
      axios.post("https://10.32.102.170:18095" + "/api/zyy/logs").then(res => {
        that.logs = res.data.data_array;
        console.log(res);
      }).catch(err => {
        console.log(err);
      });
    },
    closeSpecialEffect() {
      this.initIcon();
      for (let key in this.weatherMode) {
        if (this.weatherMode[key]) {
          if (key === "isRain") {
            Global.Func.rain();
          } else if (key === "isSnow") {
            Global.Func.snow();
          } else if (key === "isFog") {
            Global.Func.fog();
          }
        }
        this.weatherMode[key] = false;
      }
      if (!this.isSun) {
        this.isSun = Global.Func.mode();
      }
      Global.Func.closeSpecialEffects();
    },
    initIcon() {
      // let sun = document.getElementById("icon2");
      let night = document.getElementById("icon1");
      let rain = document.getElementById("icon3");
      let snow = document.getElementById("icon5");
      let fog = document.getElementById("icon4");
      // sun.style.backgroundImage = "url(" + this.imageArr.sun.sunClickUrl + ")";
      night.style.backgroundImage = "url(" + this.imageArr.night.nightUrl + ")";
      rain.style.backgroundImage = "url(" + this.imageArr.rain.rainUrl + ")";
      snow.style.backgroundImage = "url(" + this.imageArr.snow.snowUrl + ")";
      fog.style.backgroundImage = "url(" + this.imageArr.fog.fogUrl + ")";
    },
    changeMode() {
      Message.changeNav("show", "");
      Global.Func.openSpecialEffects();
      let isSun = Global.Func.mode();
      this.isSun = isSun;
      let wSun = document.getElementById("w_icon");
      // let sun = document.getElementById("icon2");
      let night = document.getElementById("icon1");
      if (isSun) {
        // sun.style.backgroundImage = "url(" + this.imageArr.sun.sunClickUrl + ")";
        wSun.style.backgroundImage = "url(" + this.imageArr.sun.sunClickUrl + ")";
        night.style.backgroundImage = "url(" + this.imageArr.night.nightUrl + ")";
      } else {
        // sun.style.backgroundImage = "url(" + this.imageArr.sun.sunUrl + ")";
        wSun.style.backgroundImage = "url(" + this.imageArr.night.nightClickUrl + ")";
        night.style.backgroundImage = "url(" + this.imageArr.night.nightClickUrl + ")";
      }
    },
    rainMode() {
      if (this.$route.name === 'Show'){
        this.weatherMode = Global.Func.rain();
        this.setWeatherMode();
      }
    },
    snowMode() {
      if (this.$route.name === 'Show'){
        this.weatherMode = Global.Func.snow();
        this.setWeatherMode();
      }
    },
    fogMode() {
      if (this.$route.name === 'Show'){
        this.weatherMode = Global.Func.fog();
        this.setWeatherMode();
      }
    },
    //设置图标
    setWeatherMode() {
      // if (this.$route.name !== "show") {
      //   Message.changeNav("show", "");

      // }

      let rain = document.getElementById("icon3");
      let snow = document.getElementById("icon5");
      let fog = document.getElementById("icon4");
      if (this.weatherMode.isRain) {
        rain.style.backgroundImage = "url(" + this.imageArr.rain.rainClickUrl + ")";
        snow.style.backgroundImage = "url(" + this.imageArr.snow.snowUrl + ")";
        fog.style.backgroundImage = "url(" + this.imageArr.fog.fogUrl + ")";
      } else if (this.weatherMode.isSnow) {
        rain.style.backgroundImage = "url(" + this.imageArr.rain.rainUrl + ")";
        snow.style.backgroundImage = "url(" + this.imageArr.snow.snowClickUrl + ")";
        fog.style.backgroundImage = "url(" + this.imageArr.fog.fogUrl + ")";
      } else if (this.weatherMode.isFog) {
        rain.style.backgroundImage = "url(" + this.imageArr.rain.rainUrl + ")";
        snow.style.backgroundImage = "url(" + this.imageArr.snow.snowUrl + ")";
        fog.style.backgroundImage = "url(" + this.imageArr.fog.fogClickUrl + ")";
      } else {
        rain.style.backgroundImage = "url(" + this.imageArr.rain.rainUrl + ")";
        snow.style.backgroundImage = "url(" + this.imageArr.snow.snowUrl + ")";
        fog.style.backgroundImage = "url(" + this.imageArr.fog.fogUrl + ")";
      }
    },
    getNowTime() {
      let that = this;
      let t = null;
      time();
      t = setInterval(time, 1000); //开始执行
      function time() {
        // clearTimeout(t); //清除定时器
        let dt = new Date();
        let y = dt.getFullYear();
        let mm = dt.getMonth() + 1;
        let d = dt.getDate();
        let weekday = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
        let day = dt.getDay();
        let h = dt.getHours();
        let m = dt.getMinutes();
        let s = dt.getSeconds();
        if (h < 10) {
          h = "0" + h;
        }
        if (mm < 10) {
          mm = "0" + mm;
        }
        if (d < 10) {
          d = "0" + d;
        }
        if (m < 10) {
          m = "0" + m;
        }
        if (s < 10) {
          s = "0" + s;
        }

        const time3 = weekday[day];  //星期四
        that.state.time = "" + y + "." + mm + "." + d;
        that.state.day = time3;
        // t = setTimeout(time, 1000); //设定定时器，循环执行
      }
    },
    getWeatherData() {
      const url = HiConfig.weatherUrl;
      const params = {};
      const that = this;
      let wSun = document.getElementById("w_icon");
      temperature();
      setInterval(temperature, 1000 * 3600); //开始执行/
      // setInterval(temperature, 1000); //开始执行
      function temperature() {
        axios.post(url, params)
            .then((response) => {
              let data = response.data.data.condition;
              that.state.temperature = data.temp;
              let weather = data.condition;
              if (weather.match("雨")) {
                weather = "雨";
              }
              if (weather.match("云")) {
                weather = "阴";
              }
              if (weather.match("雾")) {
                weather = "雾";
              }


              switch (weather) {
                case "晴":
                  wSun.style.backgroundImage = "url(" + that.weatherStyle.sunny + ")";
                  break;
                case "阴":
                  wSun.style.backgroundImage = "url(" + that.weatherStyle.cloudy + ")";
                  break;
                case "云":
                  wSun.style.backgroundImage = "url(" + that.weatherStyle.cloudy + ")";
                  break;
                case "雨":
                  wSun.style.backgroundImage = "url(" + that.weatherStyle.rainy + ")";
                  that.rainMode();
                  break;
                case "雪":
                  wSun.style.backgroundImage = "url(" + that.weatherStyle.snow + ")";
                  that.snowMode();
                  break;
                case "雾":
                  wSun.style.backgroundImage = "url(" + that.weatherStyle.foggy + ")";
                  that.fogMode();
                  break;
                case "霾":
                  wSun.style.backgroundImage = "url(" + that.weatherStyle.smog + ")";
                  break;
                default:
                  wSun.style.backgroundImage = "url(" + that.weatherStyle.sunny + ")";
              }
            })
            .catch((error) => {
              console.log("天气请求失败");
              // wSun.style.backgroundImage = "url(" + that.weatherStyle.sunny + ")";
            });
      }
    },
    // 设置场景阴影参数
    setScenenShadow() {
      console.log("?????????", Global.hiScene)
      if (Global.hiScene) {
        Global.hiScene.sceneShadow.enabled = !Global.hiScene.sceneShadow.enabled;
        console.log("sceneShadow", Global.hiScene.sceneShadow.enabled)
      }
    },
  }
};
</script>

<style scoped>
.jl_list {
  width: 100%;
  height: 160px;
  overflow: scroll;
}

.jl_item_ {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-family: "微软雅黑";
}

.jl_close {
  width: 38px;
  height: 38px;
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  background-image: url(../assets/image/icon/close.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.jl_title, .jl_item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.jl_title_ {
  text-align: center;
  color: #11EAD0;
  font-size: 18px;
  font-family: "微软雅黑";
}

.jilu_con {
  width: 660px;
  height: 222px;
  position: absolute;
  right: 15px;
  bottom: -232px;
  background-image: url(../assets/image/bg/Rectangle.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  padding: 10px 25px;
  background-color: rgba(0,0,0,0.6);
}

.header_right {
  display: flex;
  align-items: center;
}

.header_right_icon {
  width: 37px;
  height: 37px;
  margin-right: 25px;
}

.header_right_line {
  width: 2px;
  height: 19px;
  background-color: #fff;
  margin-right: 25px;
}

.header_right_icon:last-child {
  margin-right: 0;
}

.h_date_ {
  margin-bottom: 5px;
}

.h_date_2 {
  display: flex;
  justify-content: space-between;
}

.h_date {
  color: #fafafa;
  margin-left: 20px;
}

.header_left {
  display: flex;
  align-items: center;
}

.icon1 {
  background-image: url(../assets/image/icon/night.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.icon2 {
  background-image: url(../assets/image/icon/sun_click.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.icon3 {
  background-image: url(../assets/image/icon/rain.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  cursor: pointer;
}

.icon4 {
  background-image: url(../assets/image/icon/fog.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  cursor: pointer;
}

.icon5 {
  background-image: url(../assets/image/icon/snow.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  cursor: pointer;
}

.icon6 {
  background-image: url(../assets/image/icon/history.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  cursor: pointer;
}

.icon7 {
  background-image: url(../assets/image/icon/logout.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  cursor: pointer;
}

.w_icon {
  width: 37.19px;
  height: 37.19px;
  margin-left: 5px;
  background-image: url(../assets/image/icon/sun_click.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.h_text {
  width: 366.5px;
  height: 103.5px;
  background-image: url(../assets/image/icon/Maskgroup1.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.h_icon {
  width: 97px;
  height: 89px;
  background-image: url(../assets/image/icon/Mask_group.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.h_line {
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0) 60%, rgba(255, 255, 255, 0.8) 100%);
}

.header {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 105px;
  display: flex;
  justify-content: space-between;
  padding: 0 35px;
  align-items: center;
  background-image: linear-gradient(to right, rgba(25, 63, 71, 0.8) 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0) 60%, rgba(25, 63, 71, 0.8) 100%);
}
</style>
