"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HiMesh = void 0;
class HiMesh {
    constructor(bMesh, hiScene) {
        this._castSceneShadow = true;
        this._receiveShadows = true;
        this._mesh = bMesh;
        this._hiScene = hiScene;
        // 设置阴影
        if (this._castSceneShadow) {
            this.meshes.forEach(m => hiScene.sceneShadow._sg.addShadowCaster(m, false));
        }
        if (this._receiveShadows) {
            this.meshes.forEach(m => m.receiveShadows = true);
        }
    }
    set castSceneShadow(v) {
        this._castSceneShadow = v;
        if (v) {
            this.meshes.forEach(m => this._hiScene.sceneShadow._sg.addShadowCaster(m, false));
        }
        else {
            this.meshes.forEach(m => this._hiScene.sceneShadow._sg.removeShadowCaster(m, false));
        }
    }
    get castSceneShadow() {
        return this._castSceneShadow;
    }
    set receiveShadows(v) {
        this._receiveShadows = v;
        this.meshes.forEach(m => m.receiveShadows = v);
    }
    get receiveShadows() {
        return this._receiveShadows;
    }
    get meshes() {
        return this._mesh.getChildMeshes();
    }
}
exports.HiMesh = HiMesh;
