"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HiFastBillboardSystem = void 0;
const hiobject_1 = require("./hiobject");
const BABYLON = __importStar(require("@babylonjs/core/Legacy/legacy"));
const utils_1 = require("./utils");
/**
 * 该类与HiBillboard相比，CPU资源占用减少，可以渲染10k级别的billboard
 * 但缺点就是实例对象不作为独立的场景对象存在，所以相关接口没有了
 * 内部使用BABYLONJS的SolidParticleSystem实现
 *
 * @note 继承于HiBaseObject，但position、rotation等transform相关属性无法更改billboard显示位置
*/
class HiFastBillboardSystem extends hiobject_1.HiBaseObject {
    constructor(hiScene, data) {
        var _a, _b, _c;
        super(hiScene, data);
        this._type = "fast_billboard";
        // 数据检查
        data = Object.assign({}, data);
        (0, utils_1.assertIsString)(data.url);
        data.center = (_a = data.center) !== null && _a !== void 0 ? _a : [0, 0, 0];
        data.positions && (0, utils_1.assert)(data.positions.length % 3 === 0, "position的长度必须为3的倍数");
        let billboardCount = data.positions ? data.positions.length / 3 : 0; // 从positions推断billboard数量
        data.scalings && (0, utils_1.assert)(data.scalings.length === billboardCount * 3, "scalings的长度必须3倍billboard数量");
        data.colors && (0, utils_1.assert)(data.colors.length === billboardCount * 4, "colors的长度必须为4倍billboard数量");
        data.uvss && (0, utils_1.assert)(data.uvss.length === billboardCount * 4, "uvss的长度必须为4倍billboard数量");
        data.visibles && (0, utils_1.assert)(data.visibles.length === billboardCount, "visibles的长度必须为1倍billboard数量");
        data.ambientColor = (_b = data.ambientColor) !== null && _b !== void 0 ? _b : [1, 1, 1];
        data.updateFrameCount = (_c = data.updateFrameCount) !== null && _c !== void 0 ? _c : 3;
        let bScene = hiScene.bScene;
        this._center = BABYLON.Vector3.FromArray(data.center);
        this._updateFrameCount = data.updateFrameCount;
        // 初始化SPS
        this._sps = new BABYLON.SolidParticleSystem(data.name + "_SolidParticleSystem", bScene, {
            updatable: true,
            isPickable: true,
            enableDepthSort: true,
            expandable: true,
            computeBoundingBox: true, // 自动更新包围体
        });
        this._sps.billboard = true;
        this._sps.computeParticleRotation = false; // setParticles时不更新旋转
        this._sps.computeParticleColor = true; // setParticles时更新颜色
        this._sps.computeParticleTexture = true; // setParticles时更新UV
        this._sps.computeParticleVertex = false; // setParticles时不调用粒子附加的函数
        // 生成原型
        this._billboardPrimitive = BABYLON.CreatePlane(data.name + "_billboardPrimitive", {}, bScene);
        this._billboardPrimitive.setParent(this._node);
        this._billboardPrimitive.setEnabled(false);
        // 初始化粒子
        this._empty = billboardCount === 0;
        if (this._empty) {
            // 为空时加一个看不见的particle进去
            this._sps.addShape(this._billboardPrimitive, 1);
            this._sps.particles[0].isVisible = false;
        }
        else {
            this._sps.addShape(this._billboardPrimitive, billboardCount);
            this._sps.initParticles = () => {
                var _a;
                for (let p = 0; p < this._sps.nbParticles; p++) {
                    let particle = this._sps.particles[p];
                    if (data.positions)
                        particle.position.fromArray(data.positions, p * 3);
                    if (data.scalings)
                        particle.scaling.fromArray(data.scalings, p * 3);
                    if (data.colors) {
                        if (!particle.color)
                            particle.color = BABYLON.Color4.FromArray(data.colors, p * 4);
                        else
                            (_a = particle.color) === null || _a === void 0 ? void 0 : _a.fromArray(data.colors, p * 4);
                    }
                    if (data.uvss)
                        particle.uvs.fromArray(data.uvss, p * 4);
                    particle.pivot = this._center;
                    particle.translateFromPivot = true;
                    if (data.visibles)
                        particle.isVisible = data.visibles[p];
                    else
                        particle.isVisible = true;
                }
            };
        }
        this._calculating = true;
        let mesh = this._sps.buildMesh();
        mesh.setParent(this._node); // 挂到hiObject下
        let mat = new BABYLON.StandardMaterial(this.name + "_SPS_Mesh_Material", bScene);
        mat.disableLighting = true;
        mat.ambientColor = BABYLON.Color3.FromArray(data.ambientColor);
        mat.diffuseTexture = new BABYLON.Texture(data.url, bScene);
        mat.diffuseTexture.hasAlpha = true;
        mesh.material = mat;
        this._sps.mesh.material = mat;
        this._sps.initParticles();
        this._sps.setParticles();
        // 每几帧更新完所有particle
        let cur = 0;
        bScene.registerBeforeRender(() => {
            if (!this._calculating) {
                return;
            }
            // 防止删除particle时出现越界情况
            if (cur >= this._sps.nbParticles - 1) {
                this._sps.setParticles(this._sps.nbParticles - 1, this._sps.nbParticles - 1, true);
                cur = 0;
                return;
            }
            // 前进量
            const inter = Math.ceil(this._sps.nbParticles / this.updateFrameCount);
            // 和一般的接口不同，setParticles的end参数的particle也是要被绘制的，所以要减1
            let end = cur + inter - 1;
            if (end >= this._sps.nbParticles - 1) {
                this._sps.setParticles(cur, this._sps.nbParticles - 1, true);
                cur = 0;
            }
            else {
                this._sps.setParticles(cur, end, false);
                cur = end + 1;
            }
        });
    }
    toData() {
        let positions = [], scalings = [], colors = [], uvss = [], visibles = [];
        if (!this._empty) {
            positions = new Array(this.count * 3);
            scalings = new Array(this.count * 3);
            colors = new Array(this.count * 4);
            uvss = new Array(this.count * 4);
            visibles = new Array(this.count);
            for (let i = 0; i < this.count; i++) {
                let particle = this._sps.particles[i];
                particle.position.toArray(positions, i * 3);
                particle.scaling.toArray(scalings, i * 3);
                if (particle.color)
                    particle.color.toArray(colors, i * 4);
                else
                    colors.fill(1, i * 4, i * 4 + 4);
                particle.uvs.toArray(uvss, i * 4);
                visibles[i] = particle.isVisible;
            }
        }
        return Object.assign(Object.assign({}, super.toData()), { url: this.url, center: this.center.asArray(), positions,
            scalings,
            colors,
            uvss,
            visibles, ambientColor: this.ambientColor.asArray(), updateFrameCount: this.updateFrameCount });
    }
    /**
     * 添加粒子。添加后需要手动调用updateMesh()来使添加生效
     * @param props
     * @returns 粒子id
     */
    addBillboard(props) {
        let index = this._sps.nbParticles;
        this._sps.addShape(this._billboardPrimitive, 1);
        let particle = this._sps.particles[index];
        (0, utils_1.assert)(particle);
        particle.position = props.position;
        props.scaling && (particle.scaling = props.scaling);
        props.color && (particle.color = props.color);
        props.uvs && (particle.uvs = props.uvs);
        particle.pivot = this._center;
        particle.translateFromPivot = true;
        if (this._empty) {
            // 删除垫底的空粒子
            this._sps.removeParticles(0, 0);
            this._empty = false;
        }
        return particle.id;
    }
    /**
     * 删除粒子
     * @param start 开始下标
     * @param count 删除数量
     */
    removeBillboard(start, count = 1) {
        if (this._empty)
            return;
        if (start === 0 && count === this.count) {
            // 此时是清空SPS，但是SPS不能为空
            // 于是添加一个看不见的particle垫底，然后标志为空
            this._sps.addShape(this._billboardPrimitive, 1);
            this._sps.particles[this.count - 1].isVisible = false;
            this._sps.removeParticles(0, this.count - 2);
            this._empty = true;
        }
        else {
            this._sps.removeParticles(start, start + count - 1);
        }
    }
    removeBillboardById(id) {
        if (this._empty)
            return false;
        let p = this._sps.getParticleById(id);
        if (!p)
            return false;
        this.removeBillboard(p.idx);
        return true;
    }
    removeAllBillboard() {
        this.removeBillboard(0, this.count);
    }
    /**
     * 在增删billboard后需要手动调用该函数，以更新mesh
     * 否则会出现billboard不跟随视角的问题
     */
    updateMesh() {
        this._sps.buildMesh();
    }
    set url(v) {
        let mat = this.mesh.material;
        (0, utils_1.assert)(mat);
        let tex = mat.diffuseTexture;
        (0, utils_1.assert)(tex);
        tex.updateURL(v);
    }
    get url() {
        let mat = this.mesh.material;
        (0, utils_1.assert)(mat);
        let tex = mat.diffuseTexture;
        (0, utils_1.assert)(tex);
        return tex.url;
    }
    get count() {
        if (this._empty)
            return 0;
        return this._sps.nbParticles;
    }
    /**
     * 用一个mesh表示所有的billboard
     */
    get mesh() {
        return this._sps.mesh;
    }
    get ambientColor() {
        let mat = this.mesh.material;
        return mat.ambientColor;
    }
    set ambientColor(v) {
        let mat = this.mesh.material;
        mat.ambientColor = v;
    }
    set center(v) {
        this._center.copyFrom(v);
        for (let p = 0; p < this._sps.nbParticles; p++) {
            let particle = this._sps.particles[p];
            particle.pivot = this._center;
        }
    }
    /**
     * 获取pivot基准点，billboard会以这个点为中心转动
     * 注意对返回的点修改是无效的，必须调用set pivot()
     */
    get center() {
        return this._center;
    }
    get updateFrameCount() {
        return this._updateFrameCount;
    }
    set updateFrameCount(value) {
        this._updateFrameCount = value;
    }
    /**
     * 所有的billboard是否可见
     * visible=false 将不消耗CPU和GPU资源，而billboard单独的isVisible=false还是会消耗CPU资源
     */
    set visible(v) {
        this._calculating = v;
        this.mesh.setEnabled(v);
    }
    /**
     * 所有的billboard是否可见
     * visible=false 将不消耗CPU和GPU资源，而billboard单独的isVisible=false还是会消耗CPU资源
     */
    get visible() {
        return this.mesh.isEnabled();
    }
    /**
     * 根据下标返回billboard
     * @param index
     * @returns
     */
    billboardAt(index) {
        return this._sps.particles[index];
    }
    /**
     * 拾取billboard
     * 限制：billboard整个面都会被拾取所判定，无法穿过透明像素拾取到后面的billboard
     * @returns {idx: number, faceId: number} or null
     * 返回NULL代表拾取失败，idx是billboard的下标
     *
     * @see BABYLON.SolidParticleSystem.pickedParticle
     */
    pickedBillboard(pickingInfo) {
        return this._sps.pickedParticle(pickingInfo);
    }
}
exports.HiFastBillboardSystem = HiFastBillboardSystem;
