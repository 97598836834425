"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HiGltf = void 0;
const BABYLON = __importStar(require("@babylonjs/core/Legacy/legacy"));
const himesh_1 = require("./himesh");
const hiobject_1 = require("./hiobject");
require("@babylonjs/loaders/glTF/2.0");
const url_1 = require("./url");
const water_1 = require("./water");
function getDefaultHiGltfData() {
    return Object.assign(Object.assign({}, (0, hiobject_1.getDefaultHiBaseObjectData)()), { type: "gltf", url: "", baseUrl: "", castSceneShadow: true, receiveShadows: true, customMaterial: null });
}
function getRequiredHiGltfData(data) {
    let d = getDefaultHiGltfData();
    let base = (0, hiobject_1.getRequiredHiBaseObjectData)(data);
    return Object.assign(Object.assign({}, base), { url: (data === null || data === void 0 ? void 0 : data.url) || d.url, baseUrl: (data === null || data === void 0 ? void 0 : data.baseUrl) || d.baseUrl, castSceneShadow: (data === null || data === void 0 ? void 0 : data.castSceneShadow) || d.castSceneShadow, receiveShadows: (data === null || data === void 0 ? void 0 : data.receiveShadows) || d.receiveShadows, customMaterial: (data === null || data === void 0 ? void 0 : data.customMaterial) || d.customMaterial });
}
class HiGltf extends hiobject_1.HiBaseObject {
    constructor(hiScene, data) {
        super(hiScene, data);
        this._castSceneShadow = true;
        this._receiveShadows = true;
        this._loaded = false;
        // gltf加载结束事件通知器
        this.onLoadedObservable = new BABYLON.Observable();
        this._customMaterial = null;
        this._originMaterialTable = new Map();
        this._type = "gltf";
        let rd = getRequiredHiGltfData(data);
        this._url = rd.url;
        this._baseUrl = rd.baseUrl;
        if (data === null || data === void 0 ? void 0 : data.customMaterial) {
            this._customMaterial = new water_1.HiWaterMaterial(hiScene, data.customMaterial);
        }
        this._loadGltf();
    }
    _loadGltf() {
        return __awaiter(this, void 0, void 0, function* () {
            let url = (0, url_1.urlJoin)(this._url, this._baseUrl, this._scene.baseUrl);
            let successed = false;
            let rootMesh = undefined;
            try {
                let res = yield BABYLON.SceneLoader.LoadAssetContainerAsync("", url, this._scene.bScene);
                res.meshes.forEach(m => this._scene.bScene.addMesh(m));
                // 记录原有材质
                for (const mesh of res.meshes) {
                    this._originMaterialTable.set(mesh, mesh.material);
                }
                // gltf的第1个mesh就是根节点
                rootMesh = (res.meshes.length > 0 ? res.meshes[0] : res.createRootMesh());
                successed = true;
            }
            catch (e) {
                // 应尽量保证加载出所有可用数据，而不是一出错就中断
                console.error(`HiObject加载url资源失败，url: '${url}'\n`, e);
            }
            if (!rootMesh)
                // 加载失败给予一个空mesh
                rootMesh = new BABYLON.Mesh("", this._scene.bScene);
            rootMesh.parent = this._node;
            this._mesh = new himesh_1.HiMesh(rootMesh, this._scene);
            this.castSceneShadow = this.castSceneShadow;
            this.receiveShadows = this.receiveShadows;
            this._loaded = true;
            this.onLoadedObservable.notifyObservers({
                successed: successed,
            });
            this.customMaterial = this._customMaterial;
        });
    }
    get loaded() {
        return this._loaded;
    }
    set castSceneShadow(v) {
        this._castSceneShadow = v;
        if (this._mesh) {
            this._mesh.castSceneShadow = v;
        }
    }
    get castSceneShadow() {
        return this._castSceneShadow;
    }
    set receiveShadows(v) {
        this._receiveShadows = v;
        if (this._mesh) {
            this._mesh._receiveShadows = v;
        }
    }
    get receiveShadows() {
        return this._receiveShadows;
    }
    /**
     * 设置自定义材质
     */
    set customMaterial(mat) {
        this._customMaterial = mat;
        if (this._customMaterial) {
            let mat = this._customMaterial.bMaterial;
            if (this.meshes.length > 2) {
                console.log(`当前gltf有${this.meshes.length - 1}个mesh，WaterMaterial只能附着于一个mesh上否则会出现渲染问题`);
            }
            this.meshes.forEach(mesh => {
                if (mesh.material) {
                    mesh.material = mat;
                }
            });
        }
        else {
            this.meshes.forEach(mesh => {
                if (this._originMaterialTable.has(mesh)) {
                    mesh.material = this._originMaterialTable.get(mesh);
                }
                else {
                    throw Error("HiGltf中存在mesh没有记录的材质");
                }
            });
        }
    }
    get customMaterial() {
        return this._customMaterial;
    }
    toData() {
        var _a;
        return Object.assign(Object.assign({}, super.toData()), { url: this._url, baseUrl: this._baseUrl, castSceneShadow: this.castSceneShadow, receiveShadows: this.receiveShadows, customMaterial: this.customMaterial ? Object.assign({ type: this.customMaterial.type }, (_a = this.customMaterial) === null || _a === void 0 ? void 0 : _a.toData()) : null });
    }
    /**
     * 设置父节点
     * @param v
     * @param options
     * @param options.keepTransform 是否保持变换姿态。默认为不保持，这样会相对于新的父节点重新计算位置。
     * @returns
     */
    setParent(v, options) {
        super.setParent(v, options);
        // 对mesh要单独处理
        if (this._parent) {
            this.meshes.map(mesh => this._scene.bScene.addMesh(mesh));
            // 重置阴影状态
            this.castSceneShadow = this.castSceneShadow;
        }
        else {
            this.meshes.map(mesh => this._scene.bScene.removeMesh(mesh));
            // 关闭阴影
            // 在BABYLONJS的设计中，渲染和投影是分开的，一个物体可以不渲染但投影
            // 但我们需要的是模型不渲染就不投影
            this.meshes.forEach(m => this._scene.sceneShadow._sg.removeShadowCaster(m, false));
        }
    }
}
exports.HiGltf = HiGltf;
