"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HiSceneLight = void 0;
const BABYLON = __importStar(require("@babylonjs/core/Legacy/legacy"));
/**
 * 场景光照，全局的照明
 */
class HiSceneLight {
    constructor(hiScene, data) {
        this._scene = hiScene;
        this._data = {
            enabled: !((data === null || data === void 0 ? void 0 : data.enabled) === false),
            color: (data === null || data === void 0 ? void 0 : data.color) || [1, 1, 1],
            intensity: (data === null || data === void 0 ? void 0 : data.intensity) || 10,
            ambientColor: (data === null || data === void 0 ? void 0 : data.ambientColor) || [1, 1, 1],
            ambientIntensity: (data === null || data === void 0 ? void 0 : data.ambientIntensity) || 3,
            direction: (data === null || data === void 0 ? void 0 : data.direction) || [0, -1, 0],
            bAmbientColor: (data === null || data === void 0 ? void 0 : data.bAmbientColor) || [1, 1, 1],
        };
        let direction = BABYLON.Vector3.FromArray(this._data.direction);
        this._light = new BABYLON.DirectionalLight("scene light", direction, this._scene.bScene);
        this.color = this._data.color;
        this.intensity = this._data.intensity;
        this._ambientLight = new BABYLON.HemisphericLight("scene ambient light", direction.negate(), this._scene.bScene);
        this.ambientColor = this._data.ambientColor;
        this.ambientIntensity = this._data.ambientIntensity;
        this.bAmbientColor = this._data.bAmbientColor;
        this.enabled = this._data.enabled;
    }
    toData() {
        return {
            enabled: this.enabled,
            ambientColor: this.ambientColor,
            ambientIntensity: this.ambientIntensity,
            bAmbientColor: this.bAmbientColor,
            color: this.color,
            direction: this.direction,
            intensity: this.intensity,
        };
    }
    set enabled(v) {
        this._light.setEnabled(v);
        this._ambientLight.setEnabled(v);
    }
    get enabled() {
        return this._light.isEnabled();
    }
    set intensity(v) {
        this._light.intensity = v;
    }
    get intensity() {
        return this._light.intensity;
    }
    set ambientIntensity(v) {
        this._ambientLight.intensity = v;
    }
    get ambientIntensity() {
        return this._ambientLight.intensity;
    }
    set color(v) {
        this._light.diffuse.fromArray(v);
        this._light.specular.fromArray(v);
    }
    get color() {
        return this._light.diffuse.asArray();
    }
    set ambientColor(v) {
        this._ambientLight.diffuse.fromArray(v);
        this._ambientLight.specular.fromArray(v);
    }
    get ambientColor() {
        return this._ambientLight.diffuse.asArray();
    }
    set bAmbientColor(v) {
        this._scene.bScene.ambientColor.fromArray(v);
    }
    get bAmbientColor() {
        return this._scene.bScene.ambientColor.asArray();
    }
    set direction(v) {
        this._light.direction.fromArray(v);
        this._ambientLight.direction = this._light.direction.negate();
    }
    get direction() {
        return this._light.direction.asArray();
    }
}
exports.HiSceneLight = HiSceneLight;
