"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HiWaterMaterial = void 0;
const BABYLON = __importStar(require("@babylonjs/core/Legacy/legacy.js"));
const BABYLONMATERIAL = __importStar(require("@babylonjs/materials"));
const higltf_1 = require("./higltf");
const defaultHiWaterMaterialData = {
    renderObjects: [],
    renderSkybox: false,
    bumpTexture: "",
    bumpHeight: 0,
    windDirection: [1, 1],
    windForce: 10,
    waterColor: [0.1, 0.1, 0.6],
    colorBlendFactor: 0.3,
};
class HiWaterMaterial {
    constructor(hiScene, data) {
        this._renderObjects = new Set();
        this._renderSkybox = false;
        this._bumpTexture = "";
        this._scene = hiScene;
        let requiredData = {
            renderObjects: (data === null || data === void 0 ? void 0 : data.renderObjects) || defaultHiWaterMaterialData.renderObjects,
            renderSkybox: (data === null || data === void 0 ? void 0 : data.renderSkybox) || defaultHiWaterMaterialData.renderSkybox,
            bumpTexture: (data === null || data === void 0 ? void 0 : data.bumpTexture) || defaultHiWaterMaterialData.bumpTexture,
            bumpHeight: (data === null || data === void 0 ? void 0 : data.bumpHeight) || defaultHiWaterMaterialData.bumpHeight,
            windDirection: (data === null || data === void 0 ? void 0 : data.windDirection) || defaultHiWaterMaterialData.windDirection,
            windForce: (data === null || data === void 0 ? void 0 : data.windForce) || defaultHiWaterMaterialData.windForce,
            waterColor: (data === null || data === void 0 ? void 0 : data.waterColor) || defaultHiWaterMaterialData.waterColor,
            colorBlendFactor: (data === null || data === void 0 ? void 0 : data.colorBlendFactor) || defaultHiWaterMaterialData.colorBlendFactor,
        };
        this._material = new BABYLONMATERIAL.WaterMaterial("water", hiScene.bScene);
        this.bumpTexture = requiredData.bumpTexture;
        this.bumpHeight = requiredData.bumpHeight;
        this.windDirection = requiredData.windDirection;
        this.windForce = requiredData.windForce;
        this.waterColor = requiredData.waterColor;
        this.colorBlendFactor = requiredData.colorBlendFactor;
        // wave会产生几何形变，而不像bump一样只会渲染形变
        // 这里不使用wave
        this._material.waveHeight = 0;
        this._material.waveLength = 0.1;
        for (const obj of idsToObjs(hiScene, requiredData.renderObjects)) {
            this._renderObjects.add(obj);
        }
        this._renderSkybox = requiredData.renderSkybox;
        this._setRenderList();
    }
    toData() {
        return {
            renderObjects: Array.from(this._renderObjects).map(obj => obj.id),
            renderSkybox: this.renderSkybox,
            bumpTexture: this.bumpTexture,
            bumpHeight: this.bumpHeight,
            windDirection: this.windDirection,
            windForce: this.windForce,
            waterColor: this.waterColor,
            colorBlendFactor: this.colorBlendFactor,
        };
    }
    set bumpTexture(url) {
        this._bumpTexture = url;
        if (url)
            this._material.bumpTexture = new BABYLON.Texture(url, this._scene.bScene);
        else
            // @ts-ignore
            this._material.bumpTexture = undefined;
    }
    get bumpTexture() {
        return this._bumpTexture;
    }
    set windDirection(direction) {
        this._material.windDirection.set(...direction);
    }
    get windDirection() {
        return this._material.windDirection.asArray();
    }
    set waterColor(v) {
        this._material.waterColor.set(...v);
    }
    get waterColor() {
        return this._material.waterColor.asArray();
    }
    set colorBlendFactor(v) {
        this._material.colorBlendFactor = v;
    }
    get colorBlendFactor() {
        return this._material.colorBlendFactor;
    }
    set windForce(force) {
        this._material.windForce = force;
    }
    get windForce() {
        return this._material.windForce;
    }
    set bumpHeight(v) {
        this._material.bumpHeight = v;
    }
    get bumpHeight() {
        return this._material.bumpHeight;
    }
    hasRenderObject(obj) {
        return this._renderObjects.has(obj);
    }
    addRenderObject(obj) {
        if (this._renderObjects.has(obj))
            return false;
        this._renderObjects.add(obj);
        this._setRenderList();
        return true;
    }
    removeRenderObject(obj) {
        let res = this._renderObjects.delete(obj);
        res && this._setRenderList();
        return res;
    }
    clearRenderObject() {
        this._renderObjects.clear();
        this._setRenderList();
    }
    set renderSkybox(v) {
        if (this._renderSkybox === v)
            return;
        this._renderSkybox = v;
        this._setRenderList();
    }
    get renderSkybox() {
        return this._renderSkybox;
    }
    /**
     * BABYLONJS材质
     */
    get bMaterial() {
        return this._material;
    }
    /**
     * 设置水渲染列表
     */
    _setRenderList() {
        // @ts-ignore
        if (!(this._material._refractionRTT && this._material._refractionRTT.renderList
            // @ts-ignore
            && this._material._reflectionRTT && this._material._reflectionRTT.renderList))
            throw Error(`水面材质渲染列表不符合要求，无法进行后续清空操作`);
        // @ts-ignore 清空折射
        this._material._refractionRTT.renderList = [];
        // @ts-ignore 清空反射
        this._material._reflectionRTT.renderList = [];
        let that = this;
        // HiGltf没有加载好就等，加载好就直接添加渲染
        function addObjectMeshes(obj) {
            if (obj instanceof higltf_1.HiGltf) {
                if (obj.loaded) { // TODO 接口不合理。loaded应该所有object都有，这样可以都查询加载状态
                    obj.meshes.forEach(m => that._material.addToRenderList(m));
                }
                else {
                    obj.onLoadedObservable.addOnce(() => {
                        obj.meshes.forEach(m => that._material.addToRenderList(m));
                    });
                }
            }
        }
        for (const obj of this._renderObjects) {
            addObjectMeshes(obj);
        }
        if (this.renderSkybox && this._scene.skybox) {
            that._material.addToRenderList(this._scene.skybox._box);
        }
    }
    get type() {
        return "water";
    }
}
exports.HiWaterMaterial = HiWaterMaterial;
function idsToObjs(scene, ids) {
    return ids.map(id => scene.findObjectById(id)).filter(obj => !!obj);
}
