"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createCubeTextureForHdr = exports.HiSceneEnvTexture = void 0;
const BABYLON = __importStar(require("@babylonjs/core/Legacy/legacy"));
const defaultData = {
    hdrTexSize: 512,
    ddsPrefiltered: false,
};
class HiSceneEnvTexture {
    constructor(scene, data) {
        this._scene = scene;
        this._data = {
            url: data.url,
            ddsPrefiltered: data.ddsPrefiltered || defaultData.ddsPrefiltered,
            hdrTexSize: data.hdrTexSize || defaultData.hdrTexSize,
        };
        this._resetTexture();
    }
    toData() {
        return {
            url: this.url,
            ddsPrefiltered: this.ddsPrefiltered,
            hdrTexSize: this.hdrTexSize,
        };
    }
    dispose() {
        var _a;
        (_a = this._scene.bScene.environmentTexture) === null || _a === void 0 ? void 0 : _a.dispose();
        this._scene.bScene.environmentTexture = null;
    }
    _resetTexture() {
        var _a;
        (_a = this._scene.bScene.environmentTexture) === null || _a === void 0 ? void 0 : _a.dispose();
        this._scene.bScene.environmentTexture = null;
        this._scene.bScene.environmentTexture = createCubeTextureForHdr(this._scene.bScene, {
            url: this.url,
            ddsPrefiltered: this.ddsPrefiltered,
            hdrTexSize: this.hdrTexSize,
        });
    }
    get url() {
        return this._data.url;
    }
    set url(v) {
        this._data.url = v;
        this._resetTexture();
    }
    get hdrTexSize() {
        return this._data.hdrTexSize;
    }
    // PROBLEM set不生效，可能和缓存有关
    get ddsPrefiltered() {
        return this._data.ddsPrefiltered;
    }
}
exports.HiSceneEnvTexture = HiSceneEnvTexture;
/**
 * 创建hdr的CubeTexture
 * @param scene
 * @param props
 * @returns
 */
function createCubeTextureForHdr(scene, props) {
    let { url, hdrTexSize = defaultData.hdrTexSize, ddsPrefiltered = defaultData.ddsPrefiltered } = props;
    // 纹理格式
    let texFormat = "OTHER";
    if (url.endsWith(".hdr")) {
        texFormat = "HDR";
    }
    else if (url.endsWith(".dds")) {
        texFormat = "DDS";
    }
    else if (url.endsWith(".env")) {
        texFormat = "ENV";
    }
    else {
        texFormat = "OTHER";
    }
    switch (texFormat) {
        case "HDR":
            console.log(".hdr格式的环境光贴图在加载时会卡一下，推荐转换成.env格式");
            return new BABYLON.HDRCubeTexture(url, scene, hdrTexSize);
            break;
        case "DDS":
            console.log("推荐将.dds格式的环境光贴图转换成压缩率更高的.env格式");
            if (ddsPrefiltered) {
                return BABYLON.CubeTexture.CreateFromPrefilteredData(url, scene);
            }
            else {
                return new BABYLON.CubeTexture(url, scene);
            }
            break;
        case "ENV":
            return new BABYLON.CubeTexture(url, scene);
            break;
        default:
            throw Error(`错误的贴图格式，贴图文件为'${url}'`);
    }
}
exports.createCubeTextureForHdr = createCubeTextureForHdr;
