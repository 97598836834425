<template>
  <Header_></Header_>
  <Model></Model>
  <Nav v-if="show"></Nav>
  <router-view v-if="show" />
</template>

<script>

import Model from "../components/Model";
import Header_ from "../components/Header";
import Nav from '../components/Nav'
export default {
  name: "Container",
  data(){
    return{
      show:false
    }
  },
  components: {
    Model,
    Header_,
    Nav,
  },
  mounted() {
  },
  methods:{
    toggleShow(){
     this.show = true
    }
  }
};
</script>

<style scoped>

</style>
