<template>
  <div class="nav">
    <div class="nav_item" v-for="(item,index) of arr" :key="index" @click="goto(item.route,index)">
      <div class="nav_icon" v-if="chose_index === index && !doubleClick" :class="item.route+'_'"></div>
      <div class="nav_icon" v-else :class="item.route"></div>
      <div class="navtext">{{ item.name }}</div>
      <div class="navline"></div>
      <div class="bottom" v-if="chose_index === index"></div>
    </div>
  </div>
</template>

<script>
import Message from "@/js/Message";

import Global from "../js/global";
export default {
  name: "Nav",
  data() {
    return {
      arr: [],
      chose_index: "",
      doubleClick: false,
      path:''
    };
  },
  mounted() {
    const user_role = sessionStorage.getItem("user_role");
    let allow = {
      admin:[
        {
          name: "首页",
          route: "msite"
        },
        {
          name: "教学管理",
          route: "teachingmanagement"
        },
        {
          name: "监控",
          route: "monitor"
        },
        {
          name: "广播 ",
          route: "broadcast"
        },
        {
          name: "照明",
          route: "illumination"
        },
        {
          name: "门禁",
          route: "accesscontrol"
        },
        {
          name: "消防",
          route: "firecontrol"
        },
        {
          name: "能耗",
          route: "energyconsumption"
        },
        {
          name: "楼宇自控",
          route: "buildingautomation"
        }
      ],
      leadership:[
        {
          name: "首页",
          route: "msite"
        },
      ],
      logistics:[
        {
          name: "首页",
          route: "msite"
        },
        {
          name: "监控",
          route: "monitor"
        },
        {
          name: "广播 ",
          route: "broadcast"
        },
        {
          name: "照明",
          route: "illumination"
        },
        {
          name: "门禁",
          route: "accesscontrol"
        },
        {
          name: "消防",
          route: "firecontrol"
        },
        {
          name: "能耗",
          route: "energyconsumption"
        },
        {
          name: "楼宇自控",
          route: "buildingautomation"
        }
      ],
      departmentCenter:[
        {
          name: "首页",
          route: "msite"
        },
        {
          name: "教学管理",
          route: "teachingmanagement"
        },
      ],
    }
    this.arr = allow[user_role]
    Message.changeNav = this.changeNav;
    let name = JSON.parse(JSON.stringify(this.$route.name.toLowerCase()));
    this.path = JSON.parse(JSON.stringify(this.$route.name.toLowerCase()));
    if (name === 'co' || name === 'water'|| name === 'electricity'|| name === 'exhaustair'|| name === 'lift'|| name === 'airconditioning'){
      name = 'buildingautomation'
    }
    for (let i = 0; i < this.arr.length; i++) {
      if (this.arr[i].route === name) {
        this.chose_index = i;
      }
    }
  },
  methods: {
    goto(path, i) {
      if (path === this.path){
        this.$router.push({ path: '/container/show' });
        this.path = ''
        this.chose_index = '';
      }else {
        this.path = path
        let path_ = '/container/'+path
        this.chose_index = i;
        this.$router.push({ path: path_ });
      }
    },
    changeNav(path, i) {
      path = '/container/'+path
      this.chose_index = i;
      this.$router.push({ path: path });
    }
  }
};
</script>

<style scoped>
.nav_icon {
  width: 77.5px;
  height: 86px;
  margin-bottom: 5px;
}
.msite{
  background-image: url(../assets/image/icon/icon1.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}
.teachingmanagement{
  background-image: url(../assets/image/icon/icon2.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}
.monitor{
  background-image: url(../assets/image/icon/icon3.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}
.broadcast{
  background-image: url(../assets/image/icon/icon4.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}
.illumination{
  background-image: url(../assets/image/icon/icon5.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}
.accesscontrol{
  background-image: url(../assets/image/icon/icon6.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}
.firecontrol{
  background-image: url(../assets/image/icon/icon7.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}
.energyconsumption{
  background-image: url(../assets/image/icon/icon8.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}.buildingautomation{
  background-image: url(../assets/image/icon/icon9.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}
.msite_{
  background-image: url(../assets/image/icon/icon_1.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.teachingmanagement_{
  background-image: url(../assets/image/icon/icon_2.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.monitor_{
  background-image: url(../assets/image/icon/icon_3.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.broadcast_{
  background-image: url(../assets/image/icon/icon_4.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.illumination_{
  background-image: url(../assets/image/icon/icon_5.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.accesscontrol_{
  background-image: url(../assets/image/icon/icon_6.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.firecontrol_{
  background-image: url(../assets/image/icon/icon_7.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}
.energyconsumption_{
  background-image: url(../assets/image/icon/icon_8.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}
.buildingautomation_{
  background-image: url(../assets/image/icon/icon_9.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.nav_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 0 20px;
}

.nav_item:last-child .navline {
  opacity: 0;
}

.navtext {
  font-size: 24px;
  color: #fff;
  text-align: center;
  cursor: pointer;
}

.navline {
  position: absolute;
  right: -20px;
  bottom: 0;
  width: 2px;
  height: 30px;
  margin: 0 20px;
  background-image: linear-gradient(to bottom, rgba(25, 63, 71, 0) 0%, rgba(76, 176, 164, 0.8) 100%);
}

.bottom {
  width: 74.5px;
  height: 7.5px;
  background-image: url(../assets/image/icon/Rectangle53.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -37.25px;
}

.nav {
  position: fixed;
  bottom: 35px;
  height: 80px;
  left: 400px;
  display: flex;
  z-index: 9;
  justify-content: center;
  align-items: center;
  width: calc(100% - 800px);
  /*background-image: linear-gradient(to bottom, rgba(25, 63, 71, 0) 0%, rgba(107, 119, 130, 0.8) 100%);*/
}
</style>
