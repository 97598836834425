"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.urlJoin = exports.addEndSlashForUrl = void 0;
/**
 * 如果URL末尾没有左斜杠，就添加一个
 * @param url
 * @returns
 */
function addEndSlashForUrl(url) {
    return url.endsWith("/") ? url : url + "/";
}
exports.addEndSlashForUrl = addEndSlashForUrl;
/**
 * 拼接资源路径
 * @param url
 * @param baseUrl
 * @param sceneBaseUrl
 * @returns
 */
function urlJoin(url, baseUrl, sceneBaseUrl) {
    if (baseUrl)
        baseUrl = addEndSlashForUrl(baseUrl);
    else if (sceneBaseUrl)
        baseUrl = addEndSlashForUrl(sceneBaseUrl);
    return new URL(url || "", baseUrl).href;
}
exports.urlJoin = urlJoin;
