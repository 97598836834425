"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.traverseLoadHiObjectFromData = exports.loadHiObjectFromData = void 0;
const higltf_1 = require("./higltf");
const hibillboard_1 = require("./hibillboard");
const hiobject_1 = require("./hiobject");
const hiFastBillboard_1 = require("./hiFastBillboard");
function loadHiObjectFromData(hiScene, data) {
    if (data.type === "gltf" || (!data.type && data.url)) {
        return new higltf_1.HiGltf(hiScene, data);
    }
    else if (data.type === "gltf" || !data.type) {
        return new hiobject_1.HiBaseObject(hiScene, data);
    }
    else if (data.type === "billboard") {
        return new hibillboard_1.HiBillboardSystem(hiScene, data);
    }
    else if (data.type === "fast_billboard") {
        return new hiFastBillboard_1.HiFastBillboardSystem(hiScene, data);
    }
    else {
        throw Error(`type错误：${data.type}`);
    }
}
exports.loadHiObjectFromData = loadHiObjectFromData;
function traverseLoadHiObjectFromData(hiScene, data) {
    let bScene = hiScene.bScene;
    let hiObject = loadHiObjectFromData(hiScene, data);
    // load children
    if (data.children) {
        for (const childData of data.children) {
            let child = loadHiObjectFromData(hiScene, childData);
            child.setParent(hiObject);
        }
    }
    return hiObject;
}
exports.traverseLoadHiObjectFromData = traverseLoadHiObjectFromData;
